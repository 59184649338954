export enum Permissions {
  MENU_DASHBOARD = 100, //-- submenus 100 a 199
  MENU_GERENCIAR_REQUISICOES = 200, //-- submenus 200 a 199
  MENU_EASY_VIEW = 300, //-- submenus 300 a 399
  MENU_WORKFLOW = 400, //-- submenus 400 a 499
  MENU_RELATORIOS = 500, //-- submenus 500 a 599
  MENU_CADASTROS = 600, //-- submenus 600 a 699
  MENU_IMPORTACAO = 700, //-- submenus 700 a 799
  MENU_GERENCIADOR_ETIQUETAS = 800, //-- submenus 800 a 899
  MENU_FATURAMENTO = 900, //-- submenus 900 a 999
  MENU_AUDITORIA = 1000, //-- submenus 1000 a 1099
  MENU_PARAMETROS = 1100, //-- submenus 1100 a 1199
  MENU_ADMINISTRACAO_USUARIOS = 1200, //-- submenus 1200 a 1299
  MENU_CONTA_CORRENTE = 1300, //-- submenus 1300 a 1399
  MENU_PAPEIS = 1400, //-- submenus 1400 a 1499

  //-- Itens do Dashboard
  DASHBOARD_TABELA = 101,
  DASHBOARD_CARDS = 102,
  DASHBOARD_LOTE_E_REQUISICOES = 103,
  DASHBOARD_LOTE_E_REQUISICOES_MOVIMENTAR = 1031,
  DASHBOARD_ALOCACAO_CAIXAS = 104,
  DASHBOARD_ALOCACAO_CAIXAS_MOVIMENTAR = 1041,
  DASHBOARD_CAIXAS = 105,
  DASHBOARD_CAIXAS_MOVIMENTAR = 1051,
  DASHBOARD_REQUISICOES = 106,
  DASHBOARD_REQUISICOES_MOVIMENTAR = 1061,
  DASHBOARD_LOTES_PROTOCOLO = 107,

  //-- Submenu de MENU_GERENCIAR_REQUISICOES
  CAIXAS = 201,
  REQUISICOES = 202,
  STATUS_REQUISICOES = 203,
  PROTOCOLOS_PDF = 204,
  DETALHAR_REQUISICOES = 205,
  INVENTARIO = 206,

  //-- Submenu de MENU_EASY_VIEW
  PESQUISAR_DOCUMENTO = 301,
  VISUALIZAR_DOCUMENTO = 30101,
  DOWNLOAD_DOCUMENTO_DIGITAL = 30102,
  EXCLUIR_DOCUMENTO = 30103,
  UPLOAD_DOCUMENTO_DIGITAL = 30104,
  ATUALIZAR_ESTATISTICA_DOCUMENTO = 30105,
  EDITAR_DOCUMENTO = 30106,
  ASSINAR_DOCUMENTO_DIGITAL = 30107,
  CONSULTA_IMAGEM_SGD = 30108,
  COMPARTILHAR_DOCUMENTO = 30109,

  //-- Submenu de MENU_WORKFLOW
  NOVOS_PROCESSOS = 401,
  MEUS_PROCESSOS = 402,
  GERENCIAR_PROCESSOS = 403,
  SERVICOS = 404,
  TIPOS_ATIVIDADES = 405,
  FLUXO_SERVICOS = 406,

  //-- Submenu de MENU_RELATORIOS
  REL_SOLICITACOES = 501,
  REL_CENTRO_DOCUMENTACAO = 502,
  REL_INDICES = 503,
  REL_DESCARTE_TTD = 504,
  REL_PROTOCOLOS = 505,
  REL_EMPRESA_USUARIOS = 506,
  REL_EMPRESA_SETORES = 507,
  REL_CAIXAS_ARMAZENADAS_CD = 508,
  REL_CAIXAS_ARMAZENADAS_EMPRESA = 509,
  REL_CAIXAS_MOVIMENTADAS_SERVICO = 510,
  REL_ESTATISTICAS_ARMAZENAMENTO_EMPRESA = 511,
  REL_LOTES_NAO_DEVOLVIDOS = 512,
  REL_PRAZO_GUARDA = 513,
  REL_INVENTARIO_DOCUMENTOS = 514,
  REL_ARVORE_DOCUMENTAL = 515,
  REL_INVENTARIO_CAIXAS = 516,
  REL_DADOS_IMPORTACAO = 517,
  REL_TIPO_DOCUMENTO_PAGINA = 518,
  REL_FAT_PREVISAO_FINANCEIRO_PAGAR = 519,
  REL_FAT_PREVISAO_FINANCEIRO_RECEBER = 520,
  REL_FAT_BAIXA_FINANCEIRO_PAGAR = 521,
  REL_FAT_BAIXA_FINANCEIRO_RECEBER = 522,
  REL_LOTES_ARQUIVADOS = 523,
  REL_TIPOS_DOCUMENTOS = 524,
  REL_FAT_PREVISAO_FINANCEIRO_RECEBER_PAGAR = 525,
  REL_FAT_BAIXA_FINANCEIRO_RECEBER_PAGAR = 526,

  //-- Submenu de MENU_CADASTROS
  EMPRESAS = 602,
  FUNCAO = 604,
  GRUPO_ACESSO = 605,
  CENTRO_DOCUMENTACAO = 607,
  ENDERECAMENTO = 608,
  SETORES = 609,
  CONTRATOS = 610,
  TIPO_DOCUMENTOS = 613,
  TIPO_TEMPORALIDADE = 614,
  TIPO_CAIXAS = 615,
  INDICES = 616,
  PRAZO_ENVIO = 617,
  VEICULOS = 618,
  BANCOS = 619,
  DOCUMENTOS = 620,
  ARVORE_DOCUMENTAL = 621,
  SETORES_DRIVE_VIRTUAL = 625,
  DRIVE_VIRTUAL = 626,

  //-- Submenu de MENU_IMPORTACAO
  IMPORTAR_DOCUMENTOS = 701,
  IMPORTAR_VOLUMES = 702,

  //-- Submenu de MENU_FATURAMENTO
  FAT_RELATORIOS_FINANCEIROS = 901,
  FAT_BORDEROS = 902,
  FAT_CAIXA = 903,
  FAT_CONTAS_A_PAGAR = 904,
  FAT_CONTAS_A_RECEBER = 905,
  FAT_BANCOS = 906,
  FAT_TESOURARIA = 907,
  FAT_FECHAMENTO = 908,
  FAT_CLIENTE = 909,
  FAT_FORNECEDOR = 910,
  FAT_CENTRO_CUSTO = 911,
  FAT_CATEGORIA_CLIENTE = 912,
  FAT_CATEGORIA_FORNECEDOR = 913,
  FAT_FORMA_PAGAMENTO = 914,
  FAT_BAIXA_CONTAS_A_PAGAR = 915,
  FAT_BAIXA_CONTAS_A_RECEBER = 916,
  FAT_CANCELAR_CONTAS_A_PAGAR = 917,
  FAT_CANCELAR_CONTAS_A_RECEBER = 918,
  FAT_ESTORNAR_BAIXA_CONTAS_A_PAGAR = 919,
  FAT_ESTORNAR_BAIXA_CONTAS_A_RECEBER = 920,
  FAT_RECORRENCIA_LANCAMENTO = 921,
  FAT_RECEITA = 922,
  FAT_DESPESA = 923,

  //-- Submenu de MENU_AUDITORIA
  LOGS_USUARIOS = 1001,
  CONSENTIMENTOS_LGPD = 1002,
  MOVIMENTACAO_DOCUMENTOS_LGPD = 1003,

  //-- Submenu de MENU_PARAMETROS
  PARAMETRO_USUARIO = 1101,
  PARAMETRO_CENTRO_DOCUMENTACAO = 1102,
  PARAMETRO_ENDERECAMENTO = 1103,
  PARAMETRO_SOLICITACOES = 1104,
  PARAMETRO_WORKFLOW = 1105,
  PARAMETRO_CADASTRO = 1106,
  PARAMETRO_IMPORTAR_LOTES = 1107,
  PARAMETRO_GERADOR_ETIQUETAS = 1108,
  PARAMETRO_RELATORIOS = 1109,
  PARAMETRO_EASY_VIEW = 1110,
  PARAMETRO_FATURAMENTO = 1111,
  PARAMETRO_DASHBOARD = 1112,
  PARAMETRO_AUDITORIA = 1113,

  //-- Submenu de MENU_CONTA_CORRENTE
  CC_BANCO = 1301,
  CC_CONTA = 1302,
  CC_EVENTO = 1303,
  CC_MOVIMENTACAO = 1304,

  //-- Submenu de MENU_GERENCIAR_REQUISICOES de Caixas (201)
  EXIBIR_ID_CAIXA = 20101,
  EDITAR_CAIXA = 20102,
  EXCLUIR_CAIXA = 20103,
  INCLUIR_DOCUMENTO_DIGITAL = 20104,
  INCLUIR_DOCUMENTO = 20105,
  NOVO_REGISTRO_CAIXA = 20106,
  EDITAR_TIPO_DOCUMENTO_CAIXA = 20107,

  //-- Submenu de MENU_CADASTROS de Tipo de Documento (613)
  EXIBIR_ID_TIPODOCUMENTO = 61301,

  //-- Itens no submenu Cadastro de CONTRATOS (610)
  ATIVAR_CONTRATO = 61001,
  INATIVAR_CONTRATO = 61002,
  EXIBIR_ID_CONTRATO = 61003,

  // -- Tela de inventário
  HOMOLOGAR_INVENTARIO = 20601,

  //-- Submenu de MENU_ADMINISTRACAO_USUARIOS (1200)
  CONTROLE_BLOQUEIOS = 1201,
  USUARIOS = 601,
  PERMISSOES = 606,
  SETOR_PERMITIDO = 622,
  CONTRATO_PERMITIDO = 623,
  TROCA_MENSAGENS = 624,

  //-- Permissões de Papéis (1400)
  PAPEL_ADMIN = 1401
}
