import { useQuery } from 'react-query'
import { ApiDashboard } from '../../services/dashboard'
import { useAuth } from '../auth'

const QUERY_KEY = 'qkDashboard'

const ListAll = () => {
  const { user } = useAuth()
  return useQuery([QUERY_KEY, user.username], ApiDashboard.listAll)
}

export const useDashboard = {
  ListAll
}
