import {
  IDashboard,
  ITipoAtividade
} from '../../../../services/dashboard/types'
import { IMovimentacaoExtendida } from '../../../../services/movimentacao/types'
import { atrasoAtividade } from '../../../../utils/atrasoAtividade'
import { cloneData } from '../../../../utils/cloneData'

interface IData {
  descricao: string
  quantidade: number
}

interface ISummary {
  totalItens: number
  headerData: IData[]
  chartData: IData[]
}

interface IProcessedData {
  summaryData: ISummary
  tableData: IMovimentacaoExtendida[]
}

export const process = (
  data: IDashboard,
  tipoAtividade: ITipoAtividade
): IProcessedData => {
  const tmpData = cloneData(data.requisicoes)
  const movimentacoesExtendidas: Array<IMovimentacaoExtendida> = []

  tmpData.forEach((requisicao) => {
    requisicao.itens_requisicao.forEach((itemRequisicao) => {
      itemRequisicao.movimentacoes.forEach((movimentacao) => {
        if (
          movimentacao.preco_atividade.atividade.tipo_atividade
            .id_tipo_atividade === tipoAtividade.id_tipo_atividade &&
          !movimentacoesExtendidas.find(
            (movExtendida) =>
              movExtendida.id_movimentacao === movimentacao.id_movimentacao
          )
        ) {
          movimentacoesExtendidas.push({
            ...movimentacao,
            data_inicio: movimentacao.data_inicio
              ? movimentacao.data_inicio.toString()
              : '',
            data_fim: movimentacao.data_fim
              ? movimentacao.data_fim.toString()
              : undefined,
            id_item_requisicao: itemRequisicao.id_item_requisicao,
            item_requisicao: {
              id_item_requisicao: itemRequisicao.id_item_requisicao,
              requisicao: {
                id_requisicao: requisicao.id_requisicao,
                id_usuario: '',
                observacoes: requisicao.observacoes,
                previsao_inicio: requisicao.previsao_inicio,
                sugestao_inicio: requisicao.sugestao_inicio,
                usuario: requisicao.usuario,
                item_contrato: {
                  ...requisicao.item_contrato,
                  id_servico: requisicao.item_contrato.servico.id_servico,
                  contrato: {
                    ...requisicao.item_contrato.contrato,
                    data_inicio: new Date(),
                    descricao: '',
                    empresa_contratante: {
                      ...requisicao.item_contrato.contrato.empresa_contratante
                    }
                  }
                }
              },
              id_documento: itemRequisicao?.documento?.id_documento ?? '',
              id_caixa: itemRequisicao?.caixa?.id_caixa ?? '',
              observacoes: itemRequisicao.observacoes,
              documento: itemRequisicao.documento,
              caixa: itemRequisicao.caixa
            },
            id_preco_atividade: movimentacao.preco_atividade.id_preco_atividade,
            tipoItem: itemRequisicao.caixa ? 'Caixa' : 'Documento',
            preco_atividade: {
              ...movimentacao.preco_atividade,
              id_setor: movimentacao.preco_atividade.id_setor,
              atividade: {
                ...movimentacao.preco_atividade.atividade
              }
            },
            atraso: atrasoAtividade(
              movimentacao.preco_atividade.sla,
              movimentacao.data_inicio,
              new Date()
            )
          })
        }
      })
    })
  })

  const totalItens = movimentacoesExtendidas.length

  const itensPendentes = movimentacoesExtendidas.reduce(
    (previousValue: number, currentValue: IMovimentacaoExtendida) =>
      previousValue +
      (currentValue.atraso === 0 && !currentValue.id_usuario ? 1 : 0),
    0
  )
  const itensExecutando = movimentacoesExtendidas.reduce(
    (previousValue: number, currentValue: IMovimentacaoExtendida) =>
      previousValue +
      (currentValue.atraso === 0 && currentValue.id_usuario ? 1 : 0),
    0
  )
  const itensPendentesAtrasados = movimentacoesExtendidas.reduce(
    (previousValue: number, currentValue: IMovimentacaoExtendida) =>
      previousValue +
      (currentValue.atraso > 0 && !currentValue.id_usuario ? 1 : 0),
    0
  )

  const itensExecutandoAtrasados = movimentacoesExtendidas.reduce(
    (previousValue: number, currentValue: IMovimentacaoExtendida) =>
      previousValue +
      (currentValue.atraso > 0 && !!currentValue.id_usuario ? 1 : 0),
    0
  )
  const totalAtraso = movimentacoesExtendidas.reduce(
    (previousValue: number, currentValue: IMovimentacaoExtendida) =>
      previousValue + currentValue.atraso,
    0
  )

  const minimoAtraso = movimentacoesExtendidas.reduce(
    (previousValue: number, currentValue: IMovimentacaoExtendida) =>
      currentValue.atraso > 0 && currentValue.atraso < previousValue
        ? currentValue.atraso
        : previousValue,
    totalAtraso
  )

  const maximoAtraso = movimentacoesExtendidas.reduce(
    (previousValue: number, currentValue: IMovimentacaoExtendida) =>
      currentValue.atraso > 0 && currentValue.atraso > previousValue
        ? currentValue.atraso
        : previousValue,
    0
  )
  const mediaAtraso =
    itensExecutandoAtrasados + itensPendentesAtrasados === 0
      ? 0
      : totalAtraso / (itensExecutandoAtrasados + itensPendentesAtrasados)

  const chartData: IData[] = [
    {
      descricao: 'Pendentes',
      quantidade: itensPendentes
    },
    {
      descricao: 'Executando',
      quantidade: itensExecutando
    },
    {
      descricao: 'Pendentes em Atraso',
      quantidade: itensPendentesAtrasados
    },
    {
      descricao: 'Executando em Atraso',
      quantidade: itensExecutandoAtrasados
    }
  ]

  const headerData: IData[] = [
    {
      descricao: 'Média de Atraso',
      quantidade: mediaAtraso
    },
    {
      descricao: 'Atraso Mínimo',
      quantidade: minimoAtraso
    },
    {
      descricao: 'Atraso Máximo',
      quantidade: maximoAtraso
    },
    {
      descricao: 'Total de Atraso',
      quantidade: totalAtraso
    }
  ]

  return {
    summaryData: {
      totalItens,
      chartData,
      headerData
    },
    tableData: [...movimentacoesExtendidas]
  }
}
