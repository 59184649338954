import { DashboardWidgetSummary } from '../../../../types/DashboardWidget/DashboardWidgetSummary'
import { DashboardWidgetSummaryBody } from '../../../../types/DashboardWidget/DashboardWidgetSummary/DashboardWidgetSummaryBody'
import { DashboardWidgetSummaryHeader } from '../../../../types/DashboardWidget/DashboardWidgetSummary/DashboardWidgetSummaryHeader'
import { DashboadWidgetSummaryHeaderToolbar } from '../../../../types/DashboardWidget/DashboardWidgetSummary/DashboardWidgetSummaryHeader/DashboadWidgetSummaryHeaderToolbar/index.tsx'
import { DashboardWidgetSummaryHeaderDatabar } from '../../../../types/DashboardWidget/DashboardWidgetSummary/DashboardWidgetSummaryHeader/DashboardWidgetSummaryHeaderDatabar'
import { IDashboard } from '../../../../services/dashboard/types'
import { process } from './process'
import { DashboardWidget } from '../../../../types/DashboardWidget'
import { TWidgetState } from '../../../../types/DashboardWidget/WidgetState'
import { MoreHoriz } from '@mui/icons-material'
import { Permissions as pms } from '../../../../types/permissions'
import { useUsuario } from '../../../../hooks/usuario/useUsuario'
import { useNavigate } from 'react-router-dom'

interface WidgetCaixaProps {
  data: IDashboard
}

export function WidgetCaixa({ data }: WidgetCaixaProps) {
  const { data: usuario } = useUsuario.FindByUsername()
  const navigate = useNavigate()

  const permissoes = usuario?.permissoes as Array<number>

  const widgetState: TWidgetState = 'SUMMARY'
  const caixasData = process(data)

  const chartData = {
    labels: caixasData.data.map((dt) => dt.descricao),
    datasets: [
      {
        label: 'Caixas por Estado',
        data: caixasData.data.map((dt) => dt.quantidade),
        backgroundColor: ['#219653', '#F2994A', '#9B51E0', '#16BFD6'],
        hoverOffset: 4
      }
    ]
  }

  const handleMoreClick = () => {
    if (permissoes.includes(pms.CAIXAS)) {
      navigate('/gerenciar-solicitacoes/caixas')
    }
  }

  return (
    <>
      {widgetState === 'SUMMARY' && (
        <DashboardWidget.Root
          sx={{
            borderRadius: '20px',
            boxShadow:
              '0px 8px 16px -4px rgba(24, 39, 75, 0.08), 0px 6px 0px -6px rgba(0, 0, 0, 0.25)',
            backgroundColor: '#fff',
            padding: 0,
            margin: '2rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '480px',
            width: {
              xs: '100%', // for mobile
              sm: '450px', // for tablet
              md: '900px' // for desktop
            }
          }}
        >
          <DashboardWidgetSummary.Root
            sx={{
              width: '100%'
            }}
          >
            <DashboardWidgetSummaryHeader.Root>
              <DashboadWidgetSummaryHeaderToolbar.Root>
                <DashboadWidgetSummaryHeaderToolbar.Type type="Caixas" />
                <DashboadWidgetSummaryHeaderToolbar.Action
                  icon={MoreHoriz}
                  onClick={handleMoreClick}
                />
              </DashboadWidgetSummaryHeaderToolbar.Root>
              <DashboardWidgetSummaryHeader.Title
                title={caixasData.total.toString()}
              />
              <DashboardWidgetSummaryHeaderDatabar.Root>
                <DashboardWidgetSummaryHeaderDatabar.Data
                  data={caixasData.contratante.toString()}
                  title="Armazenadas na Empresa Contratante"
                />
                <DashboardWidgetSummaryHeaderDatabar.Data
                  data={caixasData.contratada.toString()}
                  title="Armazenadas na Prestadora de Serviço"
                />
              </DashboardWidgetSummaryHeaderDatabar.Root>
            </DashboardWidgetSummaryHeader.Root>
            <DashboardWidgetSummaryBody.Root>
              <DashboardWidgetSummaryBody.Bar data={chartData} />
            </DashboardWidgetSummaryBody.Root>
          </DashboardWidgetSummary.Root>
        </DashboardWidget.Root>
      )}
    </>
  )
}
