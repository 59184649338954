import { ITipoDocumento } from '../../../../services/tipoDocumento/types'

/**
 * Tipo para criação de lista de tipos de documentos para importação, vinculando
 * que campos comporão a descrição dos documentos
 *
 * @property tipoDocumento - Tipo de documento
 * @property chaves - Lista de campos de metadados disponíveis para composição
 * da descrição dos documentos
 * @property descricao - Lista de campos de metadados selecionados para
 * composição da descrição dos documentos
 */
export interface IDescricaoTipoDocumento {
  tipoDocumento: ITipoDocumento
  chaves: string[]
  descricao: string[]
}

export const blankDescricaoTipoDocumento: IDescricaoTipoDocumento = {
  tipoDocumento: {
    id_tipo_documento: '',
    codigo: '',
    descricao: '',
    guarda_digital: false,
    guarda_fisica: true,
    id_destinacao_documento: '',
    id_tipo_temporalidade: '',
    tempo_guarda: 'TEMPORARIA'
  },
  chaves: [],
  descricao: []
}
