import { Box, Button, Checkbox, FormControlLabel, Stack } from '@mui/material'
import SaveIcon from '@mui/icons-material/CheckCircleOutlined'
import CancelIcon from '@mui/icons-material/DoDisturbAltOutlined'
import { ITipoDocumento } from '../../../services/tipoDocumento/types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import msg from '../../../utils/validationMessages'
import useToast from '../../../hooks/toast/useToast'
import { useTipoDocumento } from '../../../hooks/tipoDocumento/useTipoDocumento'
import { FormMode } from '../../../types/formMode'
import { useTipoTemporalidade } from '../../../hooks/tipoTemporalidade/useTipoTemporalidade'
import { Select } from '../../../components/Form/Select'
import { Input } from '../../../components/Form/Input'
import { Loading } from '../../../components/Loading'
import { useState } from 'react'
import { useDestinacaoDocumento } from '../../../hooks/destinacaoDocumento/useDestinacaoDocumento'

const schema = yup
  .object({
    descricao: yup.string().required(msg.REQUIRED).min(4, msg.MIN(4)),
    codigo: yup.string().required(msg.REQUIRED),
    guarda_fisica: yup.boolean(),
    guarda_digital: yup.boolean(),
    id_tipo_temporalidade: yup.string().nullable(),
    id_destinacao_documento: yup.string().nullable(),
    tempo_guarda: yup
      .string()
      .required(msg.REQUIRED)
      .oneOf(Object.values(['PERMANENTE', 'TEMPORARIA', 'IMEDIATA']))
  })
  .required()

interface IForm {
  data: any
  formMode: FormMode
  setFormMode: (value: React.SetStateAction<FormMode>) => void
}

export function Form({ data, formMode, setFormMode }: IForm) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<ITipoDocumento>({
    resolver: yupResolver(schema)
  })

  const { Toast } = useToast()
  const { mutateAsync: create } = useTipoDocumento.Create()
  const { mutateAsync: update } = useTipoDocumento.Update()

  const { data: temporalidadeData, isLoading } = useTipoTemporalidade.ListAll()
  const { data: destinacaoData, isLoading: isLoadingDestinacao } =
    useDestinacaoDocumento.ListAll()

  const [tempoGuarda, setTempoGuarda] = useState<string>(data.tempo_guarda)
  const [idTipoTemporalidade, setIdTipoTemporalidade] = useState<
    string | undefined
  >(data.id_tipo_temporalidade)

  const [idDestinacaoDocumento, setIdDestinacaoDocumento] = useState<
    string | undefined
  >(data.id_destinacao_documento)

  const handleTempoGuardaChange = (tempoGuarda: string) => {
    setIdTipoTemporalidade(undefined)
    if (tempoGuarda !== 'PERMANENTE') {
      setIdDestinacaoDocumento(undefined)
    }
    setTempoGuarda(tempoGuarda)
  }

  const handleIdTipoTemporalidadeChange = (
    temporalidade: string | undefined
  ) => {
    setIdTipoTemporalidade(temporalidade)
  }

  const handleIdDestinacaoDocumentoChange = (
    destinacao: string | undefined
  ) => {
    setIdDestinacaoDocumento(destinacao)
  }

  const selectTemporalidade = temporalidadeData?.map((item) => ({
    id: item.id_tipo_temporalidade,
    descricao: `${item.tempo} - ${item.unidade} - ${item.fundamentacao}`
  }))

  const selectDestinacao = destinacaoData?.map((item) => ({
    id: item.id_destinacao_documento,
    descricao: item.descricao
  }))

  const onSubmit = async (formData: ITipoDocumento) => {
    if (idDestinacaoDocumento) {
      setValue('id_destinacao_documento', idDestinacaoDocumento)
    }
    if (idTipoTemporalidade) {
      setValue('id_tipo_temporalidade', idTipoTemporalidade)
    }
    formMode === 'CREATE'
      ? await Toast(create(formData))
      : await Toast(
          update({
            id_tipo_documento: data.id_tipo_documento,
            ...formData
          }),
          'UPDATED'
        )

    setFormMode('LIST')
  }

  if (isLoading || isLoadingDestinacao) <Loading />

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} mt={4}>
      <Stack gap={2}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2
            }}
          >
            <Input
              label="Código*"
              {...register('codigo')}
              defaultValue={data?.descricao}
              message={errors.descricao?.message}
            />
            <Input
              label="Descrição*"
              {...register('descricao')}
              defaultValue={data?.descricao}
              message={errors.descricao?.message}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={data?.guarda_fisica}
                  {...register('guarda_fisica')}
                />
              }
              sx={{
                width: '30%'
              }}
              label="Guarda Física"
            />
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={data?.guarda_digital}
                  {...register('guarda_digital')}
                />
              }
              label="Guarda Digital"
              sx={{
                width: '30%'
              }}
            />
            <Select
              fullWidth={false}
              label="Tempo de Guarda*"
              placeholder="Selecione o Tempo de Guarda"
              data={[
                { id: 'PERMANENTE', descricao: 'Permanente' },
                { id: 'IMEDIATA', descricao: 'Sem Guarda' },
                { id: 'TEMPORARIA', descricao: 'Temporária' }
              ]}
              fields={['id', 'descricao']}
              {...register('tempo_guarda')}
              defaultValue={data?.tempo_guarda ?? ''}
              message={errors.tempo_guarda?.message}
              onChange={(event) =>
                handleTempoGuardaChange(String(event.target.value))
              }
            />
          </Box>
          <Select
            disabled={tempoGuarda !== 'TEMPORARIA'}
            label={'Temporalidade' + (tempoGuarda !== 'TEMPORARIA' ? '' : '*')}
            placeholder="Selecione a temporalidade"
            data={selectTemporalidade}
            fields={['id', 'descricao', 'fundamentacao']}
            {...register('id_tipo_temporalidade')}
            value={idTipoTemporalidade ?? ''}
            onChange={(event) =>
              handleIdTipoTemporalidadeChange(String(event.target.value))
            }
            message={errors.id_tipo_temporalidade?.message}
          />
          <Select
            disabled={tempoGuarda === 'PERMANENTE'}
            label={
              'Destinação de Fim de Guarda' +
              (tempoGuarda === 'PERMANENTE' ? '' : '*')
            }
            placeholder="Selecione a destinação de fim de guarda"
            data={selectDestinacao}
            fields={['id', 'descricao']}
            {...register('id_destinacao_documento')}
            value={idDestinacaoDocumento ?? ''}
            onChange={(event) =>
              handleIdDestinacaoDocumentoChange(String(event.target.value))
            }
            message={errors.id_destinacao_documento?.message}
          />
        </Box>
      </Stack>

      <Stack direction="row" gap={2} mt={3}>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<CancelIcon />}
          onClick={() => setFormMode('LIST')}
        >
          Cancelar
        </Button>

        <Button
          type="submit"
          variant="contained"
          startIcon={<SaveIcon />}
          sx={{ width: '110px' }}
        >
          Salvar
        </Button>
      </Stack>
    </Box>
  )
}
