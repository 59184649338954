import { Box } from '@mui/material'
import { DetalhesRequisicao } from '../../../pages/Cadastros/DetalharRequisicoes/components/DetalhesRequisicao'
import { IRequisicaoEstado } from '../../../services/requisicao/types'

interface DashboardWidgetFormRequisicaoProps {
  data: IRequisicaoEstado
}

export function DashboardWidgetFormRequisicao({
  data
}: DashboardWidgetFormRequisicaoProps) {
  return (
    <Box
      sx={{
        width: '100%',
        padding: 0,
        margin: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        maxHeight: '800px',
        overflow: 'hidden',
        overflowY: 'scroll',
        marginBottom: '1.5rem'
      }}
    >
      <DetalhesRequisicao statusRequisicao={data} />
    </Box>
  )
}
