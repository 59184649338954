import { IDashboard } from '../../../../services/dashboard/types'

interface ISummary {
  data: ISummaryItem[]
  total: number
  contratante: number
  contratada: number
}

export interface ISummaryItem {
  descricao: string
  quantidade: number
}

export const process = (data: IDashboard): ISummary => {
  const dt: ISummaryItem[] = []
  let total = 0
  let contratante = 0
  let contratada = 0
  const summary = [...data.sumarioCaixas.caixas]

  const addItem = (item: ISummaryItem) => {
    if (item.quantidade > 0) {
      dt.push({
        descricao: item.descricao.toLocaleUpperCase(),
        quantidade: item.quantidade
      })
    }
  }

  summary.forEach((item) => {
    if (item.descricao.toLocaleUpperCase() === 'TOTAL') {
      total = item.quantidade
    } else if (item.descricao.toLocaleUpperCase() === 'ARMAZENADO') {
      contratada = item.quantidade
      //addItem(item)
    } else if (
      item.descricao.toLocaleUpperCase() ===
      'ARMAZENADAS EM EMPRESA CONTRATANTE'
    ) {
      contratante = item.quantidade
    } else {
      addItem(item)
    }
  })
  return {
    total,
    contratante,
    contratada,
    data: dt
  }
}
