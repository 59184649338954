import { useMemo, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search'
import AddIcon from '@mui/icons-material/AddCircle'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { useTipoDocumento } from '../../../hooks/tipoDocumento/useTipoDocumento'
import { useTipoTemporalidade } from '../../../hooks/tipoTemporalidade/useTipoTemporalidade'
import useToast from '../../../hooks/toast/useToast'
import { FormMode } from '../../../types/formMode'
import { Loading } from '../../../components/Loading'
import React from 'react'
import { useUsuario } from '../../../hooks/usuario/useUsuario'
import { Permissions } from '../../../types/permissions'
import { hasAccess } from '../../../utils/hasAccess'

interface IForm {
  setData: (value: React.SetStateAction<any>) => void
  setFormMode: (value: React.SetStateAction<FormMode>) => void
}

export function List({ setData, setFormMode }: IForm) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [q, setQ] = useState<string>('')
  const [selectedOrder, setSelectedOrder] = useState<string>('')
  const { Toast } = useToast()
  const searchInput = React.useRef<HTMLInputElement>(null)
  const { data: usuario } = useUsuario.FindByUsername()

  const handleSearchClick = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setSearchParams({ ...searchParams, q: searchTerm })
    setQ(searchTerm)
    searchInput.current?.focus()
  }

  const page = useMemo(() => {
    return Number(searchParams.get('page') ?? 1)
  }, [searchParams])

  const { mutateAsync: remove } = useTipoDocumento.Remove()
  const { data: records, isLoading } = useTipoDocumento.FindAll(
    page,
    selectedOrder,
    q
  )
  const { data: temporalidade } = useTipoTemporalidade.ListAll()

  const dataTemporalidade = Object.fromEntries(
    temporalidade?.map((t) => [
      t.id_tipo_temporalidade,
      `${t.tempo} - ${t.unidade} - ${t.fundamentacao}`
    ]) ?? []
  )
  const handleNewForm = () => {
    setFormMode('CREATE')
  }

  const handleRemove = async (id: string) => await Toast(remove(id), 'REMOVED')

  const handleEditForm = (selectedData: any) => {
    setData(selectedData)
    setFormMode('EDIT')
  }

  if (isLoading) return <Loading />

  return (
    <Box>
      <Stack
        component="form"
        onSubmit={handleSearchClick}
        direction="row"
        py={3}
        gap={3}
        mb={2}
      >
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          placeholder="Código/Descrição"
          inputRef={searchInput}
          autoFocus
          value={searchTerm}
          onChange={(event) => {
            setSearchTerm(event.target.value)
          }}
        />
        <Button variant="outlined" startIcon={<SearchIcon />} type="submit">
          Pesquisar
        </Button>
      </Stack>

      <Divider />

      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={handleNewForm}
        sx={{ float: 'right', my: '12px' }}
      >
        Novo registro
      </Button>

      <TableContainer
        component={Box}
        border={1}
        borderRadius={2}
        borderColor="#f2f2f2"
      >
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#f2f2f2' }}>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  selectedOrder === 'Codigo'
                    ? setSelectedOrder('CodigoInv')
                    : setSelectedOrder('Codigo')
                }}
              >
                Código
                {selectedOrder === 'Codigo' ? (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                )}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  selectedOrder === 'Descricao'
                    ? setSelectedOrder('DescricaoInv')
                    : setSelectedOrder('Descricao')
                }}
              >
                Descrição
                {selectedOrder === 'Descricao' ? (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                )}
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Guarda Física
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Guarda Digital
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  selectedOrder === 'TempoGuarda'
                    ? setSelectedOrder('TempoGuardaInv')
                    : setSelectedOrder('TempoGuarda')
                }}
              >
                Tempo de Guarda
                {selectedOrder === 'TempoGuarda' ? (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                )}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  selectedOrder === 'Temporalidade'
                    ? setSelectedOrder('TemporalidadeInv')
                    : setSelectedOrder('Temporalidade')
                }}
              >
                Temporalidade
                {selectedOrder === 'Temporalidade' ? (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                )}
              </TableCell>
              <TableCell width="12px" />
              <TableCell width="12px" />
            </TableRow>
          </TableHead>
          <TableBody>
            {records?.data?.map((record) => (
              <TableRow key={record.id_tipo_documento} hover>
                <TableCell>
                  {record.codigo}
                  {hasAccess(
                    usuario?.permissoes,
                    Permissions.EXIBIR_ID_TIPODOCUMENTO
                  ) ? (
                    <Typography
                      sx={{
                        fontSize: '10px',
                        fontWeight: 'bold',
                        color: 'GrayText'
                      }}
                    >
                      ({record.id_tipo_documento})
                    </Typography>
                  ) : (
                    ''
                  )}
                </TableCell>
                <TableCell>{record.descricao}</TableCell>
                <TableCell>{record.guarda_fisica ? 'SIM' : 'NÃO'}</TableCell>
                <TableCell>{record.guarda_digital ? 'SIM' : 'NÃO'}</TableCell>
                <TableCell>{record.tempo_guarda}</TableCell>
                <TableCell>
                  {dataTemporalidade[record.id_tipo_temporalidade]}
                </TableCell>
                <TableCell>
                  <Tooltip title="Editar" arrow>
                    <Button
                      variant="outlined"
                      sx={{ minWidth: '24px', padding: '6px' }}
                      onClick={() => handleEditForm(record)}
                    >
                      <EditIcon />
                    </Button>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title="Excluir" arrow>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleRemove(record.id_tipo_documento!)}
                      sx={{ minWidth: '24px', padding: '6px' }}
                    >
                      <DeleteIcon />
                    </Button>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {records?.pagination &&
        records.pagination.totalItems > records.pagination.itemsPerPage && (
          <Pagination
            count={records?.pagination.totalPages}
            sx={{ marginTop: '12px' }}
            onChange={(_, newPage) =>
              setSearchParams({ page: String(newPage) })
            }
            page={records.pagination.currentPage}
          />
        )}
    </Box>
  )
}
