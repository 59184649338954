import { Chip, Divider, Typography } from '@mui/material'

enum FORM_MODE {
  CREATE = 'INCLUIR',
  EDIT = 'EDITAR',
  LIST = 'LISTAR',
  CARD = 'LISTAR',
  VIEW = 'VISUALIZAR',
  DRIVE = 'DRIVE'
}

interface FormTitleProps {
  title: string
  subtitle?: string
}

export function FormTitle({ title, subtitle }: FormTitleProps) {
  return (
    <>
      <Typography variant="h5" mb={2} color="#333">
        {title}

        {subtitle && (
          <Chip
            label={FORM_MODE[subtitle as keyof typeof FORM_MODE]}
            color="info"
            sx={{
              fontSize: 10,
              height: 16,
              ml: 2,
              fontWeight: 600
            }}
          />
        )}
      </Typography>
      <Divider />
    </>
  )
}
