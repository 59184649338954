import {
  IDashboard,
  IRequisicaoDashboard
} from '../../../../services/dashboard/types'
import {
  ITipoAtividade,
  IMovimentacao
} from '../../../../services/movimentacao/types'
import { ILote } from '../../../../services/lote/types'
import { IRequisicaoNaoAtendida } from '../../../../services/requisicao/types'
import { atrasoAtividade } from '../../../../utils/atrasoAtividade'
import { cloneData } from '../../../../utils/cloneData'

interface IData {
  descricao: string
  quantidade: number
}

interface ISummary {
  totalItens: number
  headerData: IData[]
  chartData: IData[]
}

interface IProcessedData {
  summaryData: ISummary
  tableData: {
    requisicoes: IRequisicaoNaoAtendida[]
    lotes: ILote[]
  }
}

interface PartialProcessReturn {
  totalItens: number
  itensPendentes: number
  itensPendentesAtrasados: number
  totalAtraso: number
  minimoAtraso: number
  maximoAtraso: number
  requisicoesNaoAtendidas: IRequisicaoNaoAtendida[]
}

interface PartialBatchProcessReturn {
  totalItens: number
  totalAtraso: number
  minimoAtraso: number
  maximoAtraso: number
  itensExecutandoAtrasados: number
  itensExecutando: number
  lotes: ILote[]
}

const processLotes = (
  requisicoes: IRequisicaoDashboard[]
): PartialBatchProcessReturn => {
  function getItemRequisicaoFromMovimentacao(
    requisicao: IRequisicaoDashboard,
    id_movimencacao: string
  ): string {
    const itemRequisicao = requisicao.itens_requisicao.find((item) =>
      item.movimentacoes.some(
        (movimentacao) => movimentacao.id_movimentacao === id_movimencacao
      )
    )
    return itemRequisicao?.id_item_requisicao ?? ''
  }

  let totalItens = 0
  let totalAtraso = 0
  let minimoAtraso = 0
  let maximoAtraso = 0
  let itensExecutandoAtrasados = 0
  let itensExecutando = 0

  const tmpLotes: ILote[] = []
  requisicoes.forEach((requisicao) => {
    const lotesList = requisicao.lotes.filter((lote) =>
      lote.itens_lote.some(
        (itemLote) => itemLote.movimentacao.estado !== 'COMPLETO'
      )
    )
    lotesList.forEach((lote) => {
      const tmpLote: ILote = {
        id_lote: lote.id_lote,
        id_requisicao: requisicao.id_requisicao,
        previsao: lote.previsao,
        observacao: lote.observacao,
        assinado: lote.assinado,
        id_usuario_validou: lote.id_usuario_validou,
        validado_em: lote.validado_em,
        requisicao: {
          token: lote.requisicao?.token,
          item_contrato: {
            ...requisicao.item_contrato
          }
        },

        itens_lote: [
          ...lote.itens_lote.map((itemLote) => {
            totalItens++
            const atraso = atrasoAtividade(
              itemLote.movimentacao.preco_atividade.sla,
              itemLote.movimentacao.data_inicio,
              new Date()
            )

            itensExecutando += atraso === 0 ? 1 : 0
            itensExecutandoAtrasados += atraso > 0 ? 1 : 0

            if (atraso === 0) {
              itensExecutando++
            } else {
              itensExecutandoAtrasados++
              if (totalAtraso === 0) {
                totalAtraso = atraso
                minimoAtraso = atraso
                maximoAtraso = atraso
              } else {
                totalAtraso += atraso
                minimoAtraso =
                  minimoAtraso === 0
                    ? atraso
                    : minimoAtraso > atraso
                    ? atraso
                    : minimoAtraso
                maximoAtraso = maximoAtraso < atraso ? atraso : maximoAtraso
              }
            }
            return {
              ...itemLote,
              movimentacao: {
                ...itemLote.movimentacao,
                data_inicio: itemLote.movimentacao.data_inicio.toString(),
                data_fim:
                  itemLote.movimentacao.data_inicio.toString() ?? undefined,
                id_preco_atividade:
                  itemLote.movimentacao.preco_atividade.id_preco_atividade,
                id_item_requisicao: getItemRequisicaoFromMovimentacao(
                  requisicao,
                  itemLote.id_movimentacao
                )
              }
            }
          })
        ]
      }

      tmpLotes.push(tmpLote)
    })
  })
  return {
    totalItens,
    totalAtraso,
    minimoAtraso,
    maximoAtraso,
    itensExecutandoAtrasados,
    itensExecutando,
    lotes: [...tmpLotes]
  }
}

const processRequisicoes = (
  requisicoes: IRequisicaoDashboard[],
  tipoAtividade: ITipoAtividade
): PartialProcessReturn => {
  let totalItens = 0
  let itensPendentes = 0
  let itensPendentesAtrasados = 0
  let totalAtraso = 0
  let minimoAtraso = 0
  let maximoAtraso = 0
  const requisicoesNaoAtendidas: IRequisicaoNaoAtendida[] = []
  requisicoes.forEach((requisicao) => {
    const itensTemporarios = requisicao.itens_requisicao
      .filter((itemRequisicao) =>
        itemRequisicao.movimentacoes.some(
          (movimentacao) =>
            movimentacao.preco_atividade.atividade.tipo_atividade
              .id_tipo_atividade === tipoAtividade.id_tipo_atividade &&
            !movimentacao.id_usuario
        )
      )
      .map((itemRequisicao) => {
        const tmpMovimentacoes = itemRequisicao.movimentacoes
          .filter(
            (movimentacao) =>
              movimentacao.preco_atividade.atividade.tipo_atividade
                .id_tipo_atividade === tipoAtividade.id_tipo_atividade &&
              !movimentacao.id_usuario
          )
          .map((movimentacao) => {
            totalItens++
            const atraso = atrasoAtividade(
              movimentacao.preco_atividade.sla,
              movimentacao.data_inicio,
              new Date()
            )
            itensPendentes += atraso === 0 ? 1 : 0
            itensPendentesAtrasados += atraso > 0 ? 1 : 0

            if (totalAtraso === 0) {
              totalAtraso = atraso
              minimoAtraso = atraso
              maximoAtraso = atraso
            } else {
              totalAtraso += atraso
              minimoAtraso = atraso < minimoAtraso ? atraso : minimoAtraso
              maximoAtraso = atraso > maximoAtraso ? atraso : maximoAtraso
            }
            const tmpMovimentacao: IMovimentacao = {
              id_movimentacao: movimentacao.id_movimentacao,
              id_item_requisicao: itemRequisicao.id_item_requisicao,
              id_preco_atividade:
                movimentacao.preco_atividade.id_preco_atividade,
              id_usuario: movimentacao.id_usuario,
              previsao: movimentacao.previsao,
              observacoes: movimentacao.observacoes,
              data_inicio: movimentacao?.data_inicio.toString() ?? '',
              data_fim: movimentacao?.data_fim?.toISOString() ?? undefined,
              estado: movimentacao.estado,
              item_requisicao: {
                id_caixa: itemRequisicao.caixa?.id_caixa ?? '',
                id_documento: itemRequisicao.documento?.id_documento ?? '',
                observacoes: itemRequisicao.observacoes,
                id_item_requisicao: itemRequisicao.id_item_requisicao,
                caixa: itemRequisicao.caixa,
                documento: itemRequisicao.documento,
                endereco: itemRequisicao.endereco
              },
              preco_atividade: {
                atividade: {
                  id_atividade:
                    movimentacao.preco_atividade.atividade.id_atividade,
                  ordem: movimentacao.preco_atividade.atividade.ordem,
                  tipo_atividade: {
                    ...movimentacao.preco_atividade.atividade.tipo_atividade
                  }
                },
                data_inicio: movimentacao.preco_atividade.data_inicio,
                id_preco_atividade:
                  movimentacao.preco_atividade.id_preco_atividade,
                id_setor: movimentacao.preco_atividade.id_setor,
                sla: movimentacao.preco_atividade.sla,
                valor: movimentacao.preco_atividade.valor,
                data_fim: movimentacao.preco_atividade.data_fim
              }
            }
            return tmpMovimentacao
          })
        const tmpItemRequisicao = {
          id_caixa: itemRequisicao.caixa?.id_caixa ?? '',
          id_documento: itemRequisicao.documento?.id_documento ?? '',
          observacoes: itemRequisicao.observacoes,
          id_item_requisicao: itemRequisicao.id_item_requisicao,
          caixa: itemRequisicao.caixa,
          documento: itemRequisicao.documento,
          movimentacoes: tmpMovimentacoes,
          endereco: itemRequisicao.endereco
        }
        return tmpItemRequisicao
      })
    const tmpRequisicao: IRequisicaoNaoAtendida = {
      id_item_contrato: requisicao.item_contrato.id_item_contrato,
      observacoes: requisicao.observacoes,
      id_requisicao: requisicao.id_requisicao,
      previsao_inicio: requisicao.previsao_inicio.toString() ?? '',
      itens_requisicao: itensTemporarios,
      usuario: {
        ...requisicao.usuario
      },
      item_contrato: {
        ...requisicao.item_contrato,
        precos_atividades: []
      }
    }
    if (tmpRequisicao.itens_requisicao.length > 0)
      requisicoesNaoAtendidas.push(tmpRequisicao)
  })
  return {
    totalItens,
    itensPendentes,
    itensPendentesAtrasados,
    totalAtraso,
    minimoAtraso,
    maximoAtraso,
    requisicoesNaoAtendidas
  }
}

export const processData = (
  data: IDashboard,
  tipoAtividade: ITipoAtividade
): IProcessedData => {
  const filtro = data.requisicoes.filter((requisicao) =>
    requisicao.itens_requisicao.some((itemRequisicao) =>
      itemRequisicao.movimentacoes.some(
        (movimentacao) =>
          movimentacao.preco_atividade.atividade.tipo_atividade
            .id_tipo_atividade === tipoAtividade.id_tipo_atividade
      )
    )
  )
  let dataRequisicoes: IRequisicaoDashboard[] = []
  dataRequisicoes = cloneData(filtro)

  const preReq = processRequisicoes(dataRequisicoes, tipoAtividade)
  const preLot = processLotes(dataRequisicoes)

  const totalAtraso = preReq.totalAtraso + preLot.totalAtraso
  const totalItens = preReq.totalItens + preLot.totalItens
  const itensExecutando = preLot.itensExecutando
  const itensExecutandoAtrasados = preLot.itensExecutandoAtrasados
  const itensPendentes = preReq.itensPendentes
  const itensPendentesAtrasados = preReq.itensPendentesAtrasados
  const minimoAtraso =
    preLot.minimoAtraso < preReq.minimoAtraso
      ? preLot.minimoAtraso
      : preReq.minimoAtraso
  const maximoAtraso =
    preLot.maximoAtraso > preReq.maximoAtraso
      ? preLot.maximoAtraso
      : preReq.maximoAtraso

  let lotes: ILote[] = []
  let requisicoes: IRequisicaoNaoAtendida[] = []

  lotes = [...preLot.lotes]
  requisicoes = [...preReq.requisicoesNaoAtendidas]

  const mediaAtraso =
    itensExecutandoAtrasados + itensPendentesAtrasados === 0
      ? 0
      : totalAtraso / (itensExecutandoAtrasados + itensPendentesAtrasados)

  return {
    summaryData: {
      totalItens,
      headerData: [
        {
          descricao: 'Média de Atraso',
          quantidade: mediaAtraso
        },
        {
          descricao: 'Atraso Mínimo',
          quantidade: minimoAtraso
        },
        {
          descricao: 'Atraso Máximo',
          quantidade: maximoAtraso
        },
        {
          descricao: 'Atraso Total',
          quantidade: totalAtraso
        }
      ],
      chartData: [
        {
          descricao: 'Pendentes em Atraso',
          quantidade: itensPendentes
        },
        {
          descricao: 'Executando',
          quantidade: itensExecutando
        },
        {
          descricao: 'Executando em Atraso',
          quantidade: itensExecutandoAtrasados
        },
        {
          descricao: 'Dentro do Praso',
          quantidade: itensPendentesAtrasados
        }
      ]
    },
    tableData: {
      lotes,
      requisicoes
    }
  }
}
