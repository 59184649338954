import { useState } from 'react'
import { Box, Button, Stack } from '@mui/material'
import SaveIcon from '@mui/icons-material/CheckCircleOutlined'
import CancelIcon from '@mui/icons-material/DoDisturbAltOutlined'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ContentCopy } from '@mui/icons-material'

import useToast from '../../../hooks/toast/useToast'
import { Input } from '../../../components/Form/Input'
import msg from '../../../utils/validationMessages'
import { Select } from '../../../components/Form/Select'
import { FormMode } from '../../../types/formMode'
import { ISetor } from '../../../services/setor/types'
import { useSetor } from '../../../hooks/setor/useSetor'
import yup from '../../../types/yup.string.d'
import { masks } from '../../../utils/masks'
import { useEmpresa } from '../../../hooks/empresa/useEmpresa'
import { useUf } from '../../../hooks/uf/useUf'
import { useMunicipio } from '../../../hooks/municipio/useMunicipio'
import { validation } from '../../../utils/validations'
import { ComponentGroup } from '../../../components/ComponentGroup'
import { Loading } from '../../../components/Loading'
import { IEndereco } from '../../../services/endereco/types'

const schema = yup.object({
  nome: yup.string().required(msg.REQUIRED).min(3, msg.MIN(3)),
  email: yup.string().email(msg.EMAIL),
  telefone1: yup.string().phone(msg.PHONE),
  telefone2: yup.string().phone(msg.PHONE),
  empresa: yup.object({
    id_empresa: yup.string().required(msg.REQUIRED)
  }),
  endereco: yup.object({
    logradouro: yup.string().required(msg.REQUIRED).min(4, msg.MIN(4)),
    id_municipio: yup.string().required(msg.REQUIRED),
    numero: yup.string().required(msg.REQUIRED),
    bairro: yup.string().required(msg.REQUIRED).min(3, msg.MIN(3)),
    cep: yup.string().required(msg.REQUIRED).min(8, msg.MIN(8)),
    latitude: yup.string().real({
      message: msg.REAL({ min: -90, max: 90 }),
      minValue: -90,
      maxValue: 90
    }),
    longitude: yup.string().real({
      message: msg.REAL({ min: -180, max: 180 }),
      minValue: -180,
      maxValue: 180
    }),
    referencia: yup.string(),
    complemento: yup.string()
  })
})

const blankEndereco: IEndereco = {
  bairro: '',
  cep: '',
  id_municipio: '',
  logradouro: '',
  numero: '',
  ativo: false
}

interface IForm {
  data: ISetor
  formMode: FormMode
  setFormMode: (value: React.SetStateAction<FormMode>) => void
}

export function Form({ data, formMode, setFormMode }: IForm) {
  const [uf, setUf] = useState<string>(
    data?.endereco?.municipio?.estado?.sigla ?? 'RN'
  )
  const [id_municipio, setIdMunicipio] = useState<string>(
    data?.endereco?.id_municipio
  )
  const [id_empresa, setIdEmpresa] = useState<string>(
    data?.empresa?.id_empresa ?? ''
  )
  const [endereco, setEndereco] = useState<IEndereco>(
    data?.endereco ?? blankEndereco
  )

  const {
    register,
    handleSubmit,
    resetField,
    setValue,
    formState: { errors, isSubmitted }
  } = useForm<ISetor>({
    resolver: yupResolver(schema)
  })
  const { Toast } = useToast()
  const { mutateAsync: create } = useSetor.Create()
  const { mutateAsync: update } = useSetor.Update()
  const { data: empresas } = useEmpresa.ListAll()
  const { data: ufs } = useUf.ListAll()
  const { data: municipios, isLoading } = useMunicipio.FindByUf(uf)
  const { data: empresaEndereco } = useEmpresa.FindOne(id_empresa)

  setValue('empresa.id_empresa', id_empresa)
  setValue('endereco.id_municipio', id_municipio)

  function handleCepChange(value: string): string {
    const newValue = masks.cep({ value })
    return newValue
  }

  function handlePhoneChange(value: string): string {
    const newValue = masks.phone({ value, mandatoryDDD: true })
    return newValue
  }

  function handlePhoneBlur(value: string): string {
    const newValue = validation.clearValue(value)
    if (newValue.length === 0) {
      return ''
    }
    return value
  }

  function handleEmpresaChange(id_empresa: string) {
    setValue('empresa.id_empresa', id_empresa)
    setIdEmpresa(id_empresa)
  }

  function handleMunicipioChange(id: string) {
    setIdMunicipio(id)
    setValue('endereco.id_municipio', id)
  }

  function handleTextFieldChange(fieldName: string, value: any) {
    const newEndereco = {
      ...endereco,
      [fieldName]: value
    }
    setEndereco(newEndereco)
  }

  function handleCopyButtonClick() {
    if (!empresaEndereco) return
    const endereco = empresaEndereco.endereco
    /**
     *Endereco pode vir em branco, caso id_empresa não esteja definido.
     *Isso ocorre porque o back recebe um get sem uma string com id de empresa.
     *Get em empresa, sem id da empresa resulta em recuperação de lista
     *paginada de empresas, que não é o mesmo resultado de findOne
     */
    if (!endereco) return
    setUf(endereco?.municipio?.estado?.sigla ?? '')
    setIdMunicipio(endereco.id_municipio)
    setValue('endereco.id_municipio', endereco.id_municipio)
    setValue('endereco.bairro', endereco.bairro)
    setValue('endereco.cep', endereco.cep)
    setValue('endereco.complemento', endereco.complemento)
    setValue('endereco.latitude', endereco.latitude)
    setValue('endereco.logradouro', endereco.logradouro)
    setValue('endereco.longitude', endereco.longitude)
    setValue('endereco.numero', endereco.numero)
    setValue('endereco.referencia', endereco.referencia)
    setEndereco(endereco)
  }

  const onSubmit = async (formData: ISetor) => {
    formMode === 'CREATE'
      ? await Toast(create(formData))
      : await Toast(
          update({
            id_setor: data.id_setor,
            ...formData
          }),
          'UPDATED'
        )

    setFormMode('LIST')
  }

  if (isLoading) return <Loading />
  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} mt={4}>
      <Stack gap={2}>
        <Input
          label="Nome*"
          {...register('nome')}
          defaultValue={data?.nome}
          message={errors.nome?.message}
        />

        <Stack gap={2} direction="row">
          <Select
            label="Empresa"
            placeholder="Selecione a empresa"
            data={empresas}
            fields={['id_empresa', 'nome']}
            onChange={(event) => {
              handleEmpresaChange(String(event.target.value))
            }}
            defaultValue={data?.empresa?.id_empresa ?? ''}
            message={errors.empresa?.id_empresa?.message}
          />
          <Input
            label="E-mail"
            {...register('email')}
            defaultValue={data?.email}
            message={errors.email?.message}
          />
        </Stack>

        <Stack gap={2} direction="row">
          <Input
            label="Telefone 1"
            {...register('telefone1', {
              onChange: (event) => {
                const { value } = event.target
                event.target.value = handlePhoneChange(value)
              },
              onBlur: (event) => {
                const { value } = event.target
                event.target.value = handlePhoneBlur(value)
              }
            })}
            defaultValue={data?.telefone1}
            message={errors.telefone1?.message}
          />
          <Input
            label="Telefone 2"
            {...register('telefone2', {
              onChange: (event) => {
                const { value } = event.target
                event.target.value = handlePhoneChange(value)
              },
              onBlur: (event) => {
                const { value } = event.target
                event.target.value = handlePhoneBlur(value)
              }
            })}
            defaultValue={data?.telefone2}
            message={errors.telefone2?.message}
          />
        </Stack>
        <ComponentGroup title="Endereço">
          <Stack direction="row">
            <Button
              type="button"
              variant="outlined"
              startIcon={<ContentCopy />}
              sx={{ width: '320px' }}
              onClick={handleCopyButtonClick}
            >
              Copiar Endereço da Empresa
            </Button>
          </Stack>
          <Stack gap={2} direction="row">
            <Select
              label="Estado"
              placeholder="Selecione o Estado"
              data={ufs}
              fields={['sigla', 'nome']}
              value={uf ?? ''}
              message={isSubmitted && !uf ? msg.REQUIRED : ''}
              onChange={(e) => {
                setUf(String(e.target.value))
                setIdMunicipio('')
              }}
              onBlur={() => resetField('endereco.municipio.id_municipio')}
            />

            <Select
              label="Município"
              placeholder="Selecione o município"
              data={municipios}
              fields={['id_municipio', 'nome']}
              value={id_municipio ?? ''}
              onChange={(event) =>
                handleMunicipioChange(String(event.target.value))
              }
              defaultValue={data?.endereco?.municipio?.id_municipio}
              message={errors.endereco?.id_municipio?.message}
            />
          </Stack>

          <Input
            label="Logradouro*"
            {...register('endereco.logradouro')}
            defaultValue={data?.endereco?.logradouro}
            message={errors.endereco?.logradouro?.message}
            InputLabelProps={{ shrink: !!(endereco && endereco.logradouro) }}
          />

          <Stack gap={2} direction="row">
            <Input
              label="Número*"
              {...register('endereco.numero')}
              defaultValue={data?.endereco?.numero}
              message={errors.endereco?.numero?.message}
              InputLabelProps={{ shrink: !!(endereco && endereco.numero) }}
            />

            <Input
              label="Bairro*"
              {...register('endereco.bairro')}
              defaultValue={data?.endereco?.bairro}
              message={errors.endereco?.bairro?.message}
              InputLabelProps={{ shrink: !!(endereco && endereco.bairro) }}
            />

            <Input
              label="Cep*"
              {...register('endereco.cep', {
                onChange: (event) => {
                  const { value } = event.target
                  event.target.value = handleCepChange(value)
                }
              })}
              defaultValue={data?.endereco?.cep}
              message={errors.endereco?.cep?.message}
              InputLabelProps={{ shrink: !!(endereco && endereco.cep) }}
            />
          </Stack>

          <Input
            label="Complemento"
            {...register('endereco.complemento', {
              onChange: (event) => {
                handleTextFieldChange('complemento', event?.target.value)
              }
            })}
            defaultValue={data?.endereco?.complemento}
            InputLabelProps={{ shrink: !!(endereco && endereco.complemento) }}
          />

          <Input
            label="Referencia"
            {...register('endereco.referencia', {
              onChange: (event) => {
                handleTextFieldChange('referencia', event?.target.value)
              }
            })}
            defaultValue={data?.endereco?.referencia}
            InputLabelProps={{ shrink: !!(endereco && endereco.referencia) }}
          />

          <Stack gap={2} direction="row">
            <Input
              label="Latitude"
              {...register('endereco.latitude', {
                onChange: (event) => {
                  handleTextFieldChange('latitude', event?.target.value)
                }
              })}
              defaultValue={data?.endereco?.latitude}
              InputLabelProps={{ shrink: !!(endereco && endereco.latitude) }}
              message={errors.endereco?.latitude?.message}
            />

            <Input
              label="Longitude"
              {...register('endereco.longitude', {
                onChange: (event) => {
                  handleTextFieldChange('longitude', event?.target.value)
                }
              })}
              defaultValue={data?.endereco?.longitude}
              InputLabelProps={{ shrink: !!(endereco && endereco.longitude) }}
              message={errors.endereco?.longitude?.message}
            />
          </Stack>
        </ComponentGroup>
      </Stack>

      <Stack direction="row" gap={2} mt={3}>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<CancelIcon />}
          onClick={() => setFormMode('LIST')}
        >
          Cancelar
        </Button>

        <Button
          type="submit"
          variant="contained"
          startIcon={<SaveIcon />}
          sx={{ width: '110px' }}
        >
          Salvar
        </Button>
      </Stack>
    </Box>
  )
}
