import { Box } from '@mui/material'
import { ReactNode, useEffect, useState } from 'react'

import { useUsuario } from '../../hooks/usuario/useUsuario'
import { useDashboard } from '../../hooks/dashboard/useDashboard'
import { Loading } from '../../components/Loading'
import { IDashboard, ITipoAtividade } from '../../services/dashboard/types'
import { usePermissaoDisponivel } from '../../hooks/permissaoDisponivel/usePermissaoDisponivel'
import { IPermissaoDisponivel } from '../../services/permissaoDisponivel/types'
import { Permissions } from '../../types/permissions'
import { hasAccess } from '../../utils/hasAccess'
import { WidgetCaixa } from './Widgets/Caixa'
import { WidgetRequisicoes } from './Widgets/Requisicoes'
import { WidgetTabela } from './Widgets/Tabela'
import { WidgetProtocolo } from './Widgets/Protocolo'
import { WidgetAlocacao } from './Widgets/Alocacao'
import { WidgetLote } from './Widgets/Lote'
import { TWidgetAtividade } from './Widgets/lib/@types/TWidgetAtividade'
import { WidgetCard } from './Widgets/Card'

export type DashboardFormMode = 'ALL' | 'FORM' | 'LIST'

const blankDashData = {
  requisicoes: [],
  sumarioCaixas: { caixas: [] },
  statusRequisicoes: []
}

export function Dashboard() {
  const [tipoAtividadeAtual, setTipoAtividadeAtual] =
    useState<TWidgetAtividade>(undefined)
  const { data: usuario, isLoading } = useUsuario.FindByUsername()
  const { data: permissaoCaixa, isLoading: isLoadingPermissao } =
    usePermissaoDisponivel.FindByCodigoPermissao(105)
  const {
    data: permissaoCaixaMovimentacao,
    isLoading: isLoadingPermissaoCaixaMovimentacao
  } = usePermissaoDisponivel.FindByCodigoPermissao(1051)

  const { data: permissaoRequisicao, isLoading: isLoadingPermissaoRequisicao } =
    usePermissaoDisponivel.FindByCodigoPermissao(106)
  const {
    data: permissaoRequisicaoMovimentacao,
    isLoading: isLoadingPermissaoRequisicaoMovimentacao
  } = usePermissaoDisponivel.FindByCodigoPermissao(1061)

  const permissoes = usuario?.permissoes as Array<number>
  const { data, isLoading: isLoadingDashboardData } = useDashboard.ListAll()

  const [refData, setRefData] = useState<IDashboard>(blankDashData)

  const [tiposAtividades, setTiposAtividades] = useState<ITipoAtividade[]>([])

  const getTiposAtividade = (dashData: IDashboard): ITipoAtividade[] => {
    const requisicoes = dashData.requisicoes
    const tiposAtividades: Array<ITipoAtividade> = []
    requisicoes.forEach((requisicao) => {
      requisicao.itens_requisicao.forEach((itemRequisicao) => {
        itemRequisicao.movimentacoes.forEach((movimentacao) => {
          const { tipo_atividade } = movimentacao.preco_atividade.atividade
          if (
            !tiposAtividades.find(
              (tpAtividade) =>
                tpAtividade.id_tipo_atividade ===
                tipo_atividade.id_tipo_atividade
            )
          ) {
            tiposAtividades.push(tipo_atividade)
          }
        })
      })
    })
    if (dashData.sumarioCaixas) {
      const caixas = {
        id_tipo_atividade: 'id_caixas',
        descricao: 'Caixas',
        identificacao: 'Caixas',
        permissao: permissaoCaixa ?? ({} as IPermissaoDisponivel),
        permissao_movimentacao:
          permissaoCaixaMovimentacao ?? ({} as IPermissaoDisponivel)
      }
      tiposAtividades.push(caixas)
    }

    const requisicoesSumary = {
      id_tipo_atividade: 'id_requisicoes',
      descricao: 'Requisições',
      identificacao: 'Requisições Não Concluídas',
      permissao: permissaoRequisicao ?? ({} as IPermissaoDisponivel),
      permissao_movimentacao:
        permissaoRequisicaoMovimentacao ?? ({} as IPermissaoDisponivel)
    }
    tiposAtividades.push(requisicoesSumary)

    return tiposAtividades
  }

  useEffect(() => {
    if (
      !isLoadingDashboardData &&
      !!data &&
      data.length > 0 &&
      !isLoadingPermissao &&
      !isLoadingPermissaoRequisicao
    ) {
      let filteredData = data
      if (!hasAccess(usuario?.permissoes, Permissions.PAPEL_ADMIN)) {
        filteredData = data.map((item) => {
          return {
            ...item,
            statusRequisicoes: item.statusRequisicoes.filter(
              (requisicao) => !requisicao.servico.interno
            )
          }
        })
      }
      const localRefData = filteredData.pop() ?? blankDashData
      const tiposAtividades = getTiposAtividade(localRefData)
      setTiposAtividades(tiposAtividades)

      setRefData(localRefData)
    }
  }, [
    isLoadingDashboardData,
    data,
    isLoadingPermissao,
    isLoadingPermissaoRequisicao
  ])

  const hasPermission = (permission: number) => permissoes.includes(permission)

  const selectWidgets = (
    dados: IDashboard,
    tpAtividade: TWidgetAtividade
  ): ReactNode | null => {
    return tiposAtividades.map((tipoAtividade) => {
      if (
        [
          Permissions.DASHBOARD_TABELA,
          Permissions.DASHBOARD_CARDS,
          Permissions.DASHBOARD_LOTE_E_REQUISICOES,
          Permissions.DASHBOARD_LOTES_PROTOCOLO,
          Permissions.DASHBOARD_ALOCACAO_CAIXAS,
          Permissions.DASHBOARD_CAIXAS,
          Permissions.DASHBOARD_REQUISICOES
        ].includes(tipoAtividade.permissao.codigo_permissao)
      ) {
        if (
          !!tpAtividade &&
          tpAtividade !== 'Requisicao' &&
          tipoAtividade.id_tipo_atividade !== tpAtividade.id_tipo_atividade
        )
          return null
        if (hasPermission(tipoAtividade.permissao.codigo_permissao)) {
          switch (tipoAtividade.permissao.codigo_permissao) {
            case Permissions.DASHBOARD_CAIXAS: {
              return tpAtividade === 'Requisicao' ? null : (
                <WidgetCaixa
                  data={dados}
                  key={`widget${tipoAtividade.id_tipo_atividade}`}
                />
              )
            }

            case Permissions.DASHBOARD_CARDS: {
              return tpAtividade === 'Requisicao' ? null : (
                <>
                  <WidgetCard
                    key={`widget${tipoAtividade.id_tipo_atividade}`}
                    data={dados}
                    tipoAtividade={tipoAtividade}
                    onStateChange={handleWidgetStateChange}
                  />
                </>
                // <p>Teste - {tipoAtividade.descricao}</p>
              )
            }
            case Permissions.DASHBOARD_REQUISICOES: {
              return (
                <WidgetRequisicoes
                  data={dados}
                  key={`widget${tipoAtividade.id_tipo_atividade}`}
                  onStateChange={handleWidgetStateChange}
                />
              )
            }
            case Permissions.DASHBOARD_TABELA: {
              return tpAtividade === 'Requisicao' ? null : (
                <WidgetTabela
                  data={dados}
                  key={`widget${tipoAtividade.id_tipo_atividade}`}
                  tipoAtividade={tipoAtividade}
                  onStateChange={handleWidgetStateChange}
                />
              )
            }
            case Permissions.DASHBOARD_LOTES_PROTOCOLO: {
              return tpAtividade === 'Requisicao' ? null : (
                <WidgetProtocolo
                  data={dados}
                  key={`widget${tipoAtividade.id_tipo_atividade}`}
                  tipoAtividade={tipoAtividade}
                  onStateChange={handleWidgetStateChange}
                />
              )
            }
            case Permissions.DASHBOARD_ALOCACAO_CAIXAS: {
              return tpAtividade === 'Requisicao' ? null : (
                <WidgetAlocacao
                  data={dados}
                  key={`widget${tipoAtividade.id_tipo_atividade}`}
                  tipoAtividade={tipoAtividade}
                  onStateChange={handleWidgetStateChange}
                />
              )
            }
            case Permissions.DASHBOARD_LOTE_E_REQUISICOES: {
              return tpAtividade === 'Requisicao' ? null : (
                <WidgetLote
                  data={dados}
                  key={`widget${tipoAtividade.id_tipo_atividade}`}
                  tipoAtividade={tipoAtividade}
                  onStateChange={handleWidgetStateChange}
                />
              )
            }
            default: {
              return (
                <p key={`widget${tipoAtividade.id_tipo_atividade}`}>
                  {tipoAtividade.descricao}
                </p>
              )
            }
          }
        }
      }
      return null
    })
  }

  const handleWidgetStateChange = (tipoAtividade: TWidgetAtividade): void => {
    setTipoAtividadeAtual(tipoAtividade)
  }

  // const selectContent = (
  //   dados: IDashboard,
  //   currentFormMode: DashboardFormMode
  // ): ReactNode | null => {
  //   builder.updateWidgetsList(
  //     tiposAtividades,
  //     {
  //       data: dados,
  //       setTipoAtividadeAtual,
  //       setFormMode
  //     },
  //     formMode,
  //     tipoAtividadeAtual
  //   )

  //   if (currentFormMode !== 'ALL') {
  //     if (
  //       !!tipoAtividadeAtual &&
  //       hasPermission(tipoAtividadeAtual?.permissao.codigo_permissao)
  //     ) {
  //       const widget = builder.getDashboardWidget(
  //         tipoAtividadeAtual.id_tipo_atividade
  //       )
  //       if (!widget) return null
  //       return currentFormMode === 'LIST' && widget
  //         ? widget.renderList()
  //         : widget.renderForm()
  //     }
  //     return null
  //   }
  //   return tiposAtividades.map((tipoAtividade) => {
  //     if (hasPermission(tipoAtividade.permissao.codigo_permissao)) {
  //       const widget = builder.getDashboardWidget(
  //         tipoAtividade.id_tipo_atividade
  //       )
  //       return widget ? widget.renderSummary() : null
  //     }
  //     return null
  //   })
  // }

  if (
    isLoading ||
    isLoadingDashboardData ||
    isLoadingPermissao ||
    isLoadingPermissaoRequisicao ||
    isLoadingPermissaoCaixaMovimentacao ||
    isLoadingPermissaoRequisicaoMovimentacao
  )
    return <Loading />

  return (
    <Box
      sx={{
        display: !tipoAtividadeAtual ? 'grid' : '',
        gridTemplateColumns: {
          xs: '1fr', // for mobile
          sm: '1fr', // for tablet
          md: !tipoAtividadeAtual ? '1fr 1fr' : ''
        },
        gap: '1rem'
      }}
    >
      {/* {selectContent(refData, formMode)} */}
      {selectWidgets(refData, tipoAtividadeAtual)}
    </Box>
  )
}
