import { IDashboard } from '../../../../services/dashboard/types'
import { IRequisicaoEstado } from '../../../../services/requisicao/types'

interface IData {
  descricao: string
  quantidade: number
}

interface ISummary {
  totalItens: number
  regular: number
  atrasados: number
  headerData: IData[]
}

interface IProcessedData {
  summaryData: ISummary
  tableData: IRequisicaoEstado[]
}

export const process = (data: IDashboard): IProcessedData => {
  const tableData = [
    ...data.statusRequisicoes.sort(
      (a, b) =>
        new Date(a.previsao_inicio).getTime() -
        new Date(b.previsao_inicio).getTime()
    )
  ]

  const dtAtrasados = data.statusRequisicoes.filter((req) => req.atraso > 0)
  const atrasados = dtAtrasados.length
  const regular = data.statusRequisicoes.length - atrasados
  const totalItens = data.statusRequisicoes.length
  const totalAtraso = data.statusRequisicoes
    .map((status) => status.atraso)
    .reduce((acc, cur) => acc + cur, 0)
  const headerData: IData[] = [
    {
      descricao: 'Média de Atraso',
      quantidade: atrasados !== 0 ? Math.floor(totalAtraso / atrasados) : 0
    },
    {
      descricao: 'Atraso Mínimo',
      quantidade: dtAtrasados
        .map((atr) => atr.atraso)
        .reduce((acc, cur) => (acc < cur ? acc : cur), totalAtraso)
    },
    {
      descricao: 'Atraso Máximo',
      quantidade: dtAtrasados
        .map((atr) => atr.atraso)
        .reduce((acc, cur) => (acc > cur ? acc : cur), 0)
    },
    {
      descricao: 'Total de Atraso',
      quantidade: totalAtraso
    }
  ]

  return {
    summaryData: {
      totalItens,
      headerData,
      atrasados,
      regular
    },
    tableData
  }
}
