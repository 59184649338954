import { ICaixaNaoAtendida } from '../../../../services/caixa/types'
import { IDashboard } from '../../../../services/dashboard/types'
import { ITipoAtividade } from '../../../../services/movimentacao/types'
import { atrasoAtividade } from '../../../../utils/atrasoAtividade'
import { cloneData } from '../../../../utils/cloneData'

interface IData {
  descricao: string
  quantidade: number
}

interface ISummary {
  totalItens: number
  headerData: IData[]
  chartData: IData[]
}

interface IProcessedData {
  summaryData: ISummary
  tableData: ICaixaNaoAtendida[]
}

export const processData = (
  data: IDashboard,
  tipoAtividade: ITipoAtividade
): IProcessedData => {
  let totalItens = 0
  let totalAtraso = 0
  let itensPendentes = 0
  let itensPendentesAtrasados = 0
  let mediaAtraso = 0
  let minimoAtraso = 0
  let maximoAtraso = 0

  const caixas: Array<ICaixaNaoAtendida> = []

  const tmpRequisicoes = cloneData(data.requisicoes)

  tmpRequisicoes.forEach((requisicao) => {
    requisicao.itens_requisicao.forEach((itemRequisicao) => {
      if (itemRequisicao.caixa) {
        const movimentacao = itemRequisicao.movimentacoes[0]
        if (
          movimentacao?.preco_atividade.atividade.tipo_atividade
            .id_tipo_atividade === tipoAtividade.id_tipo_atividade
        ) {
          totalItens++
          const atraso = atrasoAtividade(
            movimentacao?.preco_atividade?.sla ?? 0,
            movimentacao?.data_inicio,
            new Date()
          )
          if (atraso === 0) {
            itensPendentes++
          } else {
            totalAtraso += atraso
            maximoAtraso = atraso > maximoAtraso ? atraso : maximoAtraso
            minimoAtraso =
              minimoAtraso === 0
                ? atraso
                : atraso < minimoAtraso
                ? atraso
                : minimoAtraso
            itensPendentesAtrasados++
          }
          const { caixa } = itemRequisicao
          const newCaixa: ICaixaNaoAtendida = {
            id_caixa: caixa.id_caixa,
            conteudo: caixa.conteudo,
            identificacao: caixa.identificacao,
            ativo: true,
            tipoCaixa: {
              id_tipo_caixa: caixa.tipoCaixa.id_tipo_caixa,
              descricao: caixa.tipoCaixa.descricao,
              tamanho: caixa.tipoCaixa.tamanho,
              acondiciona_caixas: caixa.tipoCaixa.acondiciona_caixas,
              acondiciona_documentos: caixa.tipoCaixa.acondiciona_documentos
            },
            item_requisicao: {
              id_requisicao: requisicao.id_requisicao,
              movimentacoes: [
                {
                  id_movimentacao:
                    itemRequisicao.movimentacoes[0].id_movimentacao
                }
              ]
            },
            documentos: [
              ...caixa.documentos.map((documento) => ({
                id_documento: documento.id_documento,
                descricao: documento.descricao,
                tipo_documento: {
                  id_tipo_documento: documento.tipo_documento.id_tipo_documento,
                  descricao: documento.tipo_documento.descricao,
                  codigo: documento.tipo_documento.codigo
                }
              }))
            ]
          }
          caixas.push(newCaixa)
        }
      }
    })
  })

  mediaAtraso =
    itensPendentesAtrasados === 0 ? 0 : totalAtraso / itensPendentesAtrasados

  const headerData: IData[] = [
    {
      descricao: 'Média de Atraso',
      quantidade: mediaAtraso
    },
    {
      descricao: 'Atraso Mínimo',
      quantidade: minimoAtraso
    },
    {
      descricao: 'Atraso Máximo',
      quantidade: maximoAtraso
    },
    {
      descricao: 'Total de Atraso',
      quantidade: totalAtraso
    }
  ]
  const chartData: IData[] = [
    {
      descricao: 'Pendentes em Atraso',
      quantidade: itensPendentes
    },
    {
      descricao: 'Dentro do Praso',
      quantidade: itensPendentesAtrasados
    }
  ]

  return {
    summaryData: {
      totalItens,
      chartData,
      headerData
    },
    tableData: [...caixas]
  }
}
