import { useEffect, useMemo, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from '@mui/material'
import { Input } from '../../../components/Form/Input'
import SendIcon from '@mui/icons-material/Send'
import SearchIcon from '@mui/icons-material/Search'
import CachedIcon from '@mui/icons-material/Cached'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { FormMode } from '../../../types/formMode'
import { Loading } from '../../../components/Loading'
import React from 'react'
import { Link } from 'react-router-dom'
import { useRequisicao } from '../../../hooks/requisicao/useRequisicao'
import { useUsuario } from '../../../hooks/usuario/useUsuario'
import { Permissions } from '../../../types/permissions'
import { formata } from '../../../utils/formataData'
import { IRequisicao } from '../../../services/requisicao/types'
import { getCodigo } from '../../../utils/getCodigo'
import { hasAccess } from '../../../utils/hasAccess'
import { useLocation, useNavigate } from 'react-router-dom'
import UnreadMessagesDisplay from './components/UnreadMessagesDisplay'
import { ShowMessage } from '../../../components/ShowMessage'
//
interface IForm {
  setData: (value: React.SetStateAction<any>) => void
  setFormMode: (value: React.SetStateAction<FormMode>) => void
}

export function List({ setData, setFormMode }: IForm) {
  const location = useLocation()
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)

  const [selectedEstado, setSelectedEstado] = useState<string>('')
  const [selectedFirstDate, setSelectedFirstDate] = useState<string>('')
  const [selectedSecondDate, setSelectedSecondDate] = useState<string>('')
  const [fixedDate, setFixedDate] = useState<string>('')
  const [dateSearch, setDateSearch] = useState<boolean>(false)
  //const [dateRecords, setDateRecords] = useState<any>()
  const [message, setMessage] = useState<string>()
  const [selectedOrder, setSelectedOrder] = useState<string>('DataInv')

  //const [searchParams, setSearchParams] = useSearchParams()
  const [searchTerm, setSearchTerm] = useState<string>(
    searchParams.get('q') || ''
  )
  const [q, setQ] = useState<string>(searchTerm)
  const searchInput = React.useRef<HTMLInputElement>(null)

  const [idRequisicao, setIdRequisicao] = useState<string>('')

  const { data: statusRequisicao, isLoading: isLoadingStatusRequisicao } =
    useRequisicao.FindStatusById(idRequisicao)

  const { data: usuario } = useUsuario.FindByUsername()

  const handleSearchClick = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    searchParams.set('q', searchTerm)
    setQ(searchTerm)
    navigate(`${location.pathname}?${searchParams.toString()}`)
    searchInput.current?.focus()
  }

  const handlePageChange = (_: any, newPage: number) => {
    searchParams.set('page', String(newPage))
    // Atualiza a URL no navegador
    navigate(`${location.pathname}?${searchParams.toString()}`)
  }

  const page = useMemo(() => {
    return Number(searchParams.get('page') ?? 1)
  }, [searchParams])

  const request_data = localStorage.getItem('@easydoc:onlyIncomplete')
  let onlyIncomplete: any
  if (request_data) {
    onlyIncomplete = request_data
  }

  const getEstadoExecucao = (requisicao: IRequisicao): string => {
    if (!requisicao.itens_requisicao) return 'COMPLETO'
    if (
      requisicao.itens_requisicao.every(
        (itemRequisicao) => itemRequisicao.movimentacoes.length === 0
      )
    ) {
      return 'COMPLETO'
    }
    return 'EXECUTANDO/PENDENTE'
  }

  const isAdminUser = hasAccess(usuario?.permissoes, Permissions.PAPEL_ADMIN)

  const { data: records, isLoading } = useRequisicao.FindAllAdmin(
    page,
    q,
    isAdminUser,
    selectedOrder
  )
  const { data: incompleteRecords, isLoading: isLoadingIncompleteRecords } =
    useRequisicao.FindAllIncomplete(page, q, isAdminUser, selectedOrder)
  const { data: dateRecords } = useRequisicao.FindAllDate(
    page,
    fixedDate,
    q,
    isAdminUser,
    selectedOrder
  )
  const { data: incompleteByDateRecords } =
    useRequisicao.FindAllIncompleteByDate(
      page,
      fixedDate,
      q,
      isAdminUser,
      selectedOrder
    )

  const handleEstadoChange = (value: string) => {
    setSelectedEstado(value)
  }

  const handleDateChange = (value: string) => {
    value === '' ? setDateSearch(false) : setDateSearch(true)
    setFixedDate(value)
  }

  let fullData: IRequisicao[] = []
  let pagination

  if (
    records &&
    (onlyIncomplete === 'true' ||
      (selectedEstado === 'EXECUTANDO/PENDENTE' && !dateSearch))
  ) {
    fullData = incompleteRecords?.data ?? []
    pagination = incompleteRecords?.pagination
  } else if (dateSearch && selectedEstado !== 'EXECUTANDO/PENDENTE') {
    fullData = dateRecords?.data ?? []
    pagination = dateRecords?.pagination
  } else if (selectedEstado === 'EXECUTANDO/PENDENTE' && dateSearch) {
    fullData = incompleteByDateRecords?.data ?? []
    pagination = incompleteByDateRecords?.pagination
  } else {
    fullData = records?.data ?? []
    pagination = records?.pagination
  }

  const handleViewForm = (data: any) => {
    setIdRequisicao(data.id_requisicao)
  }
  const handleMessages = (data: any) => {
    localStorage.setItem('@easydoc:req_user', data.id_usuario)
    localStorage.setItem('@easydoc:req_id', data.id_requisicao)
  }

  useEffect(() => {
    if (statusRequisicao) {
      setData(statusRequisicao)
      setFormMode('VIEW')
    }
  }, [statusRequisicao, q])

  if (isLoading || isLoadingStatusRequisicao || isLoadingIncompleteRecords)
    return <Loading />

  window.addEventListener('beforeunload', function () {
    localStorage.removeItem('@easydoc:onlyIncomplete')
  })

  return (
    <Box>
      <Stack
        component="form"
        onSubmit={handleSearchClick}
        direction="row"
        py={3}
        gap={3}
        mb={1}
      >
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          placeholder="Código da Requisição/Contrato/Serviço/Requisitante"
          inputRef={searchInput}
          autoFocus
          value={searchTerm}
          onChange={(event) => {
            setSearchTerm(event.target.value)
          }}
        />
        <Button variant="outlined" startIcon={<SearchIcon />} type="submit">
          Pesquisar
        </Button>
        <Button variant="outlined" onClick={() => window.location.reload()}>
          <CachedIcon />
        </Button>
      </Stack>
      <Stack direction="row" mt={-2} mb={2} gap={1}>
        <Input
          label="Data Inicial"
          type="date"
          value={selectedFirstDate}
          onChange={(event) => {
            setSelectedFirstDate(String(event.target.value))
            setSelectedSecondDate(String(event.target.value))
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
        <Input
          label="Data Final"
          type="date"
          value={selectedSecondDate}
          onChange={(event) =>
            setSelectedSecondDate(String(event.target.value))
          }
          InputLabelProps={{
            shrink: true
          }}
        />
        <Button
          variant="contained"
          startIcon={<SearchIcon />}
          onClick={() =>
            handleDateChange(selectedFirstDate + selectedSecondDate)
          }
        ></Button>
        <FormControl size="small" fullWidth>
          <InputLabel id="execucao-label">Execução</InputLabel>
          <Select
            labelId="execucoes-label"
            id="execucao-ctrl"
            label="Execução"
            value={selectedEstado}
            onChange={(e: any) => handleEstadoChange(String(e.target.value))}
          >
            <MenuItem value="Todos">Todos</MenuItem>
            <MenuItem value="EXECUTANDO/PENDENTE">EXECUTANDO/PENDENTE</MenuItem>
          </Select>
        </FormControl>
      </Stack>

      <Divider />

      <TableContainer
        component={Box}
        border={1}
        borderRadius={2}
        borderColor="#f2f2f2"
      >
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#f2f2f2' }}>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Código
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  minWidth: 150,
                  cursor: 'pointer'
                }}
                onClick={() => {
                  selectedOrder === 'Contrato'
                    ? setSelectedOrder('ContratoInv')
                    : setSelectedOrder('Contrato')
                }}
              >
                Contrato
                {selectedOrder === 'Contrato' ? (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                )}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  //minWidth: 150,
                  cursor: 'pointer'
                }}
                onClick={() => {
                  selectedOrder === 'Servico'
                    ? setSelectedOrder('ServicoInv')
                    : setSelectedOrder('Servico')
                }}
              >
                Serviço
                {selectedOrder === 'Servico' ? (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                )}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  minWidth: 190,
                  cursor: 'pointer'
                }}
                onClick={() => {
                  selectedOrder === 'Data'
                    ? setSelectedOrder('DataInv')
                    : setSelectedOrder('Data')
                }}
              >
                Data de Requisição
                {selectedOrder === 'Data' ? (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                )}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  minWidth: 150,
                  cursor: 'pointer'
                }}
                onClick={() => {
                  selectedOrder === 'Requisitante'
                    ? setSelectedOrder('RequisitanteInv')
                    : setSelectedOrder('Requisitante')
                }}
              >
                Requisitante
                {selectedOrder === 'Requisitante' ? (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                )}
              </TableCell>

              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Execução
              </TableCell>
              <TableCell width="12px" />
              <TableCell width="12px" />
            </TableRow>
          </TableHead>
          <TableBody>
            {fullData.map((record) => {
              return (
                <TableRow key={record.id_requisicao} hover>
                  <TableCell>{getCodigo(record.id_requisicao ?? '')}</TableCell>
                  <TableCell>
                    {record.item_contrato?.contrato.descricao}
                  </TableCell>
                  <TableCell>{record.item_contrato?.servico.nome}</TableCell>
                  <TableCell>
                    {formata(
                      record.created_at?.toString() ?? '',
                      'dd/MM/yyyy HH:mm'
                    )}
                  </TableCell>
                  <TableCell>{record.usuario.pessoa.nome}</TableCell>
                  <TableCell>{getEstadoExecucao(record)}</TableCell>
                  <TableCell>
                    <Tooltip title="Visualizar" arrow>
                      <Button
                        variant="outlined"
                        sx={{ minWidth: '24px', padding: '6px' }}
                        onClick={() => handleViewForm(record)}
                      >
                        <SearchIcon />
                      </Button>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Link
                      to="/gerenciar-solicitacoes/mensagens"
                      hidden={
                        !usuario?.permissoes.includes(
                          Permissions.TROCA_MENSAGENS
                        )
                      }
                    >
                      <Tooltip title="Mensagens" arrow>
                        <Button
                          variant="outlined"
                          sx={{ minWidth: '24px', padding: '6px' }}
                          onClick={() => handleMessages(record)}
                        >
                          <SendIcon />
                          <UnreadMessagesDisplay
                            reqId={record.id_requisicao ?? ''}
                          />
                        </Button>
                      </Tooltip>
                    </Link>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && pagination.totalItems > pagination.itemsPerPage && (
        <Pagination
          count={pagination.totalPages}
          sx={{ marginTop: '12px' }}
          onChange={handlePageChange}
          page={pagination.currentPage}
        />
      )}
      <ShowMessage
        hasError={!!message}
        message={message}
        onClose={() => setMessage(undefined)}
      />
    </Box>
  )
}
