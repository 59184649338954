import {
  Stack,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Box,
  MenuItem,
  Select as SelectMaterial,
  InputLabel,
  Button,
  TextField
} from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { ITipoCaixa } from '../../services/tipoCaixa/types'
import { ITipoDocumento } from '../../services/tipoDocumento/types'
import { ITipoTemporalidade } from '../../services/tipoTemporalidade/types'
import { Input } from '../Form/Input'
import SearchIcon from '@mui/icons-material/Search'
import { AdvancedFindAllParams } from '../../services/caixa/types'
import { IContrato } from '../../services/contrato/types'
import { ISetor } from '../../services/setor/types'
import { useArvoreDocumental } from '../../hooks/arvoreDcoumental/useArvoreDocumental'

interface SearchBoxProps extends AdvancedFindAllParams {
  setAdvancedSearchParams: (advancedSearchParams: AdvancedFindAllParams) => void
  descricaoBusca: string
  busca?: string
  tiposCaixas?: ITipoCaixa[]
  tipoCaixaExibir?: boolean
  idTipoCaixa?: string
  tipoDocumentos?: ITipoDocumento[]
  tipoDocumentoExibir?: boolean
  idTipoDocumento?: string
  tiposTemporalidades?: ITipoTemporalidade[]
  tipoTemporalidadeExibir?: boolean
  idTipoTemporalidade?: string
  dataExibir?: boolean
  data?: string
  sequenciaExibir?: boolean
  sequencia?: string
  dadosPessoaisExibir?: boolean
  dadosPessoais?: string
  dadosCriancasAdolescentesExibir?: boolean
  dadosCriancasAdolescentes?: string
  dadosPessoaisSensiveisExibir?: boolean
  dadosPessoaisSensiveis?: string
  dadosAtivosExibir?: boolean
  dadosAtivos?: string
  estadoAssinaturaExibir?: boolean
  estadoAssinatura?: string
  dadosContratoExibir?: boolean
  dadosSetorExibir?: boolean
  enderecoExibir?: boolean
  endereco?: string
  contratos?: IContrato[]
  setores?: ISetor[]
  idContrato?: string
  idSetor?: string
}

export function SearchBox({
  setAdvancedSearchParams,
  descricaoBusca,
  busca = '',
  tiposCaixas = [],
  tipoCaixaExibir = false,
  idTipoCaixa = '',
  tipoDocumentos = [],
  tipoDocumentoExibir = false,
  idTipoDocumento = '',
  tiposTemporalidades = [],
  tipoTemporalidadeExibir = false,
  idTipoTemporalidade = '',
  dataExibir = false,
  data = '',
  sequenciaExibir = false,
  sequencia = '',
  dadosPessoaisExibir = false,
  dadosPessoais = '',
  dadosCriancasAdolescentesExibir = false,
  dadosCriancasAdolescentes = '',
  dadosPessoaisSensiveisExibir = false,
  dadosPessoaisSensiveis = '',
  dadosAtivosExibir = false,
  dadosAtivos = 'true',
  estadoAssinaturaExibir = false,
  estadoAssinatura = '',
  enderecoExibir = false,
  endereco = '',
  idContrato = '',
  idSetor = '',
  contratos = [],
  setores = [],
  dadosContratoExibir = false,
  dadosSetorExibir = false
}: SearchBoxProps) {
  const [buscaSelecionada, setBuscaSelecionada] = useState<string>(busca)

  const [idContratoSelecionado, setIdContratoSelecionado] =
    useState<string>(idContrato)
  const [idSetorSelecionado, setIdSetorSelecionado] = useState<string>(idSetor)
  const [enderecoSelecionado, setEnderecoSelecionado] =
    useState<string>(endereco)

  const [idTipoCaixaSelecionado, setIdTipoCaixaSelecionado] =
    useState<string>(idTipoCaixa)

  const [idTipoTemporalidadeSelecionada, setIdTipoTemporalidadeSelecionado] =
    useState<string>(idTipoTemporalidade)

  const [idTipoDocumentoSelecionado, setIdTipoDocumentoSelecionado] =
    useState<string>(idTipoDocumento)

  const [sequenciaSelecionada, setSequenciaSelecionado] =
    useState<string>(sequencia)

  const [dadosPessoaisSelecionado, setDadosPessoaisSelecionado] =
    useState<string>(dadosPessoais)

  const [
    dadosCriancasAdolescentesSelecionado,
    setDadosCriancasAdolescentesSelecionado
  ] = useState<string>(dadosCriancasAdolescentes)

  const [
    dadosPessoaisSensiveisSelecionado,
    setDadosPessoaisSensiveisSelecionado
  ] = useState<string>(dadosPessoaisSensiveis)

  const [dadosAtivosSelecionados, setDadosAtivosSelecionados] =
    useState<string>(dadosAtivos)

  const [estadoAssinaturaSelecionado, setEstadoAssinaturaSelecionado] =
    useState<string>(estadoAssinatura)

  const [dataSelecionada, setDataSelecionada] = useState<string>(data)

  const [idEmpresa, setIdEmpresa] = useState<string>('')

  const { data: tiposDocumentosSetor } =
    useArvoreDocumental.FindTiposDocumentosBySetor(idSetorSelecionado)

  const setoresFiltrados = setores.filter(
    (setor) => setor.id_empresa === idEmpresa
  )

  useEffect(() => {
    let idEmpresaDefault = ''
    if (idContrato != '') {
      const contrato = contratos?.find(
        (contrato) => contrato.id_contrato === String(idContrato)
      )
      if (contrato) {
        idEmpresaDefault = contrato.empresa_contratante.id_empresa
      }
      setIdEmpresa(idEmpresaDefault)
    }
  }, [])

  const handleSearchClick = (event: React.FormEvent<HTMLFormElement>) => {
    function isValidDate(d: any) {
      return d instanceof Date && isFinite(+d)
    }

    event.preventDefault()
    let searchPrms: AdvancedFindAllParams = {
      busca: buscaSelecionada.trim() !== '' ? buscaSelecionada : ''
    }

    if (idContratoSelecionado !== '') {
      searchPrms = {
        ...searchPrms,
        id_contrato: idContratoSelecionado
      }
    }

    if (idSetorSelecionado !== '') {
      searchPrms = {
        ...searchPrms,
        id_setor: idSetorSelecionado
      }
    }
    if (idTipoCaixaSelecionado !== '') {
      searchPrms = {
        ...searchPrms,
        id_tipo_caixa: idTipoCaixaSelecionado
      }
    }
    if (idTipoDocumentoSelecionado !== '') {
      searchPrms = {
        ...searchPrms,
        id_tipo_documento: idTipoDocumentoSelecionado
      }
    }

    if (idTipoTemporalidadeSelecionada !== '') {
      searchPrms = {
        ...searchPrms,
        id_tipo_temporalidade: idTipoTemporalidadeSelecionada
      }
    }
    if (isValidDate(new Date(dataSelecionada.trim()))) {
      searchPrms = {
        ...searchPrms,
        data: dataSelecionada
      }
    }
    if (sequenciaSelecionada.trim() !== '') {
      searchPrms = {
        ...searchPrms,
        sequencia: sequenciaSelecionada
      }
    }
    if (dadosPessoaisSelecionado !== '') {
      searchPrms = {
        ...searchPrms,
        dados_pessoais: dadosPessoaisSelecionado
      }
    }
    if (dadosCriancasAdolescentesSelecionado !== '') {
      searchPrms = {
        ...searchPrms,
        dados_criancas_adolescentes: dadosCriancasAdolescentesSelecionado
      }
    }
    if (dadosPessoaisSensiveisSelecionado !== '') {
      searchPrms = {
        ...searchPrms,
        dados_pessoais_sensiveis: dadosPessoaisSensiveisSelecionado
      }
    }
    if (dadosAtivosSelecionados !== '') {
      searchPrms = {
        ...searchPrms,
        dados_ativos: dadosAtivosSelecionados
      }
    }
    if (estadoAssinaturaSelecionado !== '') {
      searchPrms = {
        ...searchPrms,
        estado_assinatura: estadoAssinaturaSelecionado
      }
    }

    if (enderecoSelecionado !== '') {
      searchPrms = {
        ...searchPrms,
        endereco: enderecoSelecionado
      }
    }

    setAdvancedSearchParams(searchPrms)

    searchInput.current?.focus()
  }

  const searchInput = useRef<HTMLInputElement>(null)

  const tiposDocumentosExibir =
    idSetorSelecionado === '' ? tipoDocumentos : tiposDocumentosSetor

  return (
    <Stack margin={2} component="form" onSubmit={handleSearchClick}>
      <Stack gap={1}>
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          placeholder={descricaoBusca}
          inputRef={searchInput}
          autoFocus
          value={buscaSelecionada}
          onChange={(event) => {
            setBuscaSelecionada(String(event.target.value))
          }}
        />
        {(dadosSetorExibir || dadosContratoExibir) && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: 2
            }}
          >
            {dadosContratoExibir && (
              <FormControl size="small" fullWidth>
                <InputLabel id="contratos-label">Contrato</InputLabel>
                <SelectMaterial
                  labelId="contratos-label"
                  id="contrato-ctrl"
                  label="Contrato"
                  value={idContratoSelecionado}
                  onChange={(event) => {
                    setIdContratoSelecionado(String(event.target.value))
                    setIdSetorSelecionado('')
                    const contrato = contratos?.find(
                      (contrato) =>
                        contrato.id_contrato === String(event.target.value)
                    )
                    if (contrato) {
                      setIdEmpresa(contrato.empresa_contratante.id_empresa)
                    }
                  }}
                >
                  <MenuItem value="" key={'contrato-none'}>
                    Todos
                  </MenuItem>
                  {contratos.map((contrato) => (
                    <MenuItem
                      value={contrato.id_contrato}
                      key={contrato.id_contrato}
                    >
                      {contrato.descricao}
                    </MenuItem>
                  ))}
                </SelectMaterial>
              </FormControl>
            )}
            {dadosSetorExibir && (
              <FormControl size="small" fullWidth>
                <InputLabel id="setor-label">Setor</InputLabel>
                <SelectMaterial
                  labelId="setores-label"
                  id="setor-ctrl"
                  label="Setor"
                  value={idSetorSelecionado}
                  onChange={(event) => {
                    setIdSetorSelecionado(String(event.target.value))
                  }}
                >
                  <MenuItem value="" key={'contrato-none'}>
                    Todos
                  </MenuItem>
                  {setoresFiltrados?.map((setor) => (
                    <MenuItem value={setor.id_setor} key={setor.id_setor}>
                      {setor.nome}
                    </MenuItem>
                  ))}
                </SelectMaterial>
              </FormControl>
            )}
          </Box>
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: 2
          }}
        >
          {dataExibir && (
            <Input
              label="Data"
              type="date"
              value={dataSelecionada}
              onChange={(event) =>
                setDataSelecionada(String(event.target.value))
              }
              InputLabelProps={{
                shrink: true
              }}
            />
          )}
          {sequenciaExibir && (
            <Input
              label={'Sequência'}
              value={sequenciaSelecionada}
              onChange={(event) => {
                setSequenciaSelecionado(String(event.target.value))
              }}
            />
          )}
          {tipoDocumentoExibir && (
            <FormControl size="small" fullWidth>
              <InputLabel id="tipos-documentos-label">
                Tipo de documento
              </InputLabel>
              <SelectMaterial
                labelId="tipos-documentos-label"
                id="tipo-documento-ctrl"
                label="Tipo de Documento"
                value={idTipoDocumentoSelecionado}
                onChange={(event) => {
                  setIdTipoDocumentoSelecionado(String(event.target.value))
                }}
              >
                <MenuItem value="" key={'tipo-documento-none'}>
                  Todos
                </MenuItem>
                {tiposDocumentosExibir?.map((tipoDocumento) => (
                  <MenuItem
                    value={tipoDocumento.id_tipo_documento}
                    key={tipoDocumento.id_tipo_documento}
                  >
                    {tipoDocumento.descricao}
                  </MenuItem>
                ))}
              </SelectMaterial>
            </FormControl>
          )}
          {tipoTemporalidadeExibir && (
            <FormControl size="small" fullWidth>
              <InputLabel id="tipos-temporalidades-label">
                Tipo de temporalidade
              </InputLabel>
              <SelectMaterial
                labelId="tipos-temporalidades-label"
                id="tipo-temporalidade-ctrl"
                label="Tipo de temporalidade"
                value={idTipoTemporalidadeSelecionada}
                onChange={(event) => {
                  setIdTipoTemporalidadeSelecionado(String(event.target.value))
                }}
              >
                <MenuItem value="" key={'tipo-temporalidade-none'}>
                  Todas
                </MenuItem>
                {tiposTemporalidades.map((tipoTemporalidade) => (
                  <MenuItem
                    value={tipoTemporalidade.id_tipo_temporalidade}
                    key={tipoTemporalidade.id_tipo_temporalidade}
                  >
                    {`${tipoTemporalidade.tempo} - ${tipoTemporalidade.unidade} - ${tipoTemporalidade.fundamentacao}`}
                  </MenuItem>
                ))}
              </SelectMaterial>
            </FormControl>
          )}
          {tipoCaixaExibir && (
            <FormControl size="small" fullWidth>
              <InputLabel id="tipos-caixas-label">Tipo de caixa</InputLabel>
              <SelectMaterial
                labelId="tipos-caixas-label"
                id="tipo-caixa-ctrl"
                label="Tipo de caixa"
                value={idTipoCaixaSelecionado}
                onChange={(event) => {
                  setIdTipoCaixaSelecionado(String(event.target.value))
                }}
              >
                <MenuItem value="" key={'tipo-caixa-none'}>
                  Todos
                </MenuItem>
                {tiposCaixas.map((tipoCaixa) => (
                  <MenuItem
                    value={tipoCaixa.id_tipo_caixa}
                    key={tipoCaixa.id_tipo_caixa}
                  >
                    {tipoCaixa.descricao}
                  </MenuItem>
                ))}
              </SelectMaterial>
            </FormControl>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: 2,
            padding: 0
          }}
        >
          {dadosPessoaisExibir && (
            <FormControl>
              <FormLabel id="dados-pessoais-label">Dados pessoais</FormLabel>
              <RadioGroup
                row
                aria-labelledby="dados-pessoais-label"
                name="dados_pessoais"
                value={dadosPessoaisSelecionado}
                onChange={(event) => {
                  setDadosPessoaisSelecionado(String(event.target.value))
                }}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Sim"
                  sx={{
                    height: '1rem',
                    '& .MuiSvgIcon-root': {
                      fontSize: 14
                    }
                  }}
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="Não"
                  sx={{
                    height: '1rem',
                    '& .MuiSvgIcon-root': {
                      fontSize: 14
                    }
                  }}
                />
                <FormControlLabel
                  value=""
                  control={<Radio />}
                  label="Todos"
                  sx={{
                    height: '1rem',
                    '& .MuiSvgIcon-root': {
                      fontSize: 14
                    }
                  }}
                />
              </RadioGroup>
            </FormControl>
          )}
          {dadosCriancasAdolescentesExibir && (
            <FormControl>
              <FormLabel id="dados-criancas-adolescentes-label">
                Dados de crianças ou adolescentes
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="dados-criancas-adolescentes-label"
                name="dadosCriancasAdolescentes"
                value={dadosCriancasAdolescentesSelecionado}
                onChange={(event) => {
                  setDadosCriancasAdolescentesSelecionado(
                    String(event.target.value)
                  )
                }}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Sim"
                  sx={{
                    height: '1rem',
                    '& .MuiSvgIcon-root': {
                      fontSize: 14
                    }
                  }}
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="Não"
                  sx={{
                    height: '1rem',
                    '& .MuiSvgIcon-root': {
                      fontSize: 14
                    }
                  }}
                />
                <FormControlLabel
                  value=""
                  control={<Radio />}
                  label="Todos"
                  sx={{
                    height: '1rem',
                    '& .MuiSvgIcon-root': {
                      fontSize: 14
                    }
                  }}
                />
              </RadioGroup>
            </FormControl>
          )}
          {dadosPessoaisSensiveisExibir && (
            <FormControl>
              <FormLabel id="dados-pessoais-sensiveis-label">
                Dados pessoais sensíveis
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="dados-pessoais-sensiveis-label"
                name="dados_pessoais-sensiveis"
                value={dadosPessoaisSensiveisSelecionado}
                onChange={(event) => {
                  setDadosPessoaisSensiveisSelecionado(
                    String(event.target.value)
                  )
                }}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Sim"
                  sx={{
                    height: '1rem',
                    '& .MuiSvgIcon-root': {
                      fontSize: 14
                    }
                  }}
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="Não"
                  sx={{
                    height: '1rem',
                    '& .MuiSvgIcon-root': {
                      fontSize: 14
                    }
                  }}
                />
                <FormControlLabel
                  value=""
                  control={<Radio />}
                  label="Todos"
                  sx={{
                    height: '1rem',
                    '& .MuiSvgIcon-root': {
                      fontSize: 14
                    }
                  }}
                />
              </RadioGroup>
            </FormControl>
          )}
          {dadosAtivosExibir && (
            <FormControl>
              <FormLabel id="ativos">Ativos</FormLabel>
              <RadioGroup
                row
                aria-labelledby="ativos"
                name="ativos"
                value={dadosAtivosSelecionados}
                onChange={(event) => {
                  setDadosAtivosSelecionados(String(event.target.value))
                }}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Sim"
                  sx={{
                    height: '1rem',
                    '& .MuiSvgIcon-root': {
                      fontSize: 14
                    }
                  }}
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="Não"
                  sx={{
                    height: '1rem',
                    '& .MuiSvgIcon-root': {
                      fontSize: 14
                    }
                  }}
                />
              </RadioGroup>
            </FormControl>
          )}
          {estadoAssinaturaExibir && (
            <FormControl>
              <FormLabel id="dados-assinados-label">
                Documentos assinados
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="dados-assinados-label"
                name="dados-assinados"
                value={estadoAssinaturaSelecionado}
                onChange={(event) => {
                  setEstadoAssinaturaSelecionado(String(event.target.value))
                }}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Sim"
                  sx={{
                    height: '1rem',
                    '& .MuiSvgIcon-root': {
                      fontSize: 14
                    }
                  }}
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="Não"
                  sx={{
                    height: '1rem',
                    '& .MuiSvgIcon-root': {
                      fontSize: 14
                    }
                  }}
                />
                <FormControlLabel
                  value=""
                  control={<Radio />}
                  label="Todos"
                  sx={{
                    height: '1rem',
                    '& .MuiSvgIcon-root': {
                      fontSize: 14
                    }
                  }}
                />
              </RadioGroup>
            </FormControl>
          )}
          {enderecoExibir && (
            <Input
              label={'Localização'}
              fullWidth={false}
              value={enderecoSelecionado}
              onChange={(event) => {
                setEnderecoSelecionado(String(event.target.value))
              }}
            />
          )}
        </Box>

        <Stack alignItems="flex-end">
          <Button variant="contained" type="submit">
            <SearchIcon />
            Pesquisar
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}
