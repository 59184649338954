import { useState, useMemo } from 'react'
import {
  Box,
  Button,
  Divider,
  Modal,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material'
import { useSearchParams } from 'react-router-dom'
//import { ILog } from '../../../services/log/types'
import SearchIcon from '@mui/icons-material/Search'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import InfoIcon from '@mui/icons-material/Info'
import { useLog } from '../../../hooks/log/useLog'
import { formata } from '../../../utils/formataData'
import React from 'react'
import { Loading } from '../../../components/Loading'
import { Input } from '../../../components/Form/Input'
import { useContrato } from '../../../hooks/contrato/useContrato'

/*interface RevealedState {
  [key: string]: boolean
}*/

export function List() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [q, setQ] = useState<string>('')
  const searchInput = React.useRef<HTMLInputElement>(null)
  //const [revealed, setRevealed] = useState<RevealedState>({})
  const [selectedFirstDate, setSelectedFirstDate] = useState<string>('')
  const [selectedSecondDate, setSelectedSecondDate] = useState<string>('')
  const [fixedDate, setFixedDate] = useState<string>('')
  const [dateSearch, setDateSearch] = useState<boolean>(false)
  const [showInfo, setShowInfo] = useState<boolean>(false)
  const [selectedInfo, setSelectedInfo] = useState<any>()
  const [contrato, setContrato] = useState<string>('')
  const [selectedOrder, setSelectedOrder] = useState<string>('DataInv')

  /*const toggleReveal = (id: string) => {
    setRevealed((prev) => ({
      ...prev,
      [id]: !prev[id]
    }))
  }*/

  const handleDateChange = (value: string) => {
    value === '' ? setDateSearch(false) : setDateSearch(true)
    setFixedDate(value)
  }

  /*const censor = (value: string) => {
    return value.replace(/.(?=.{4})/g, '*')
  }*/

  const getCodigo = (id: string) => {
    return id.split('-').pop() || ''
  }

  const getModulo = (acao: string) => {
    const acaoModulo = acao.split('_').pop() || ''
    const modulo =
      acaoModulo.charAt(0).toUpperCase() + acaoModulo.slice(1).toLowerCase()
    return modulo
  }

  const handleShowInfo = () => {
    setShowInfo(true)
  }

  const handleSearchClick = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setSearchParams({ ...searchParams, q: searchTerm, contrato })
    setQ(searchTerm)
    searchInput.current?.focus()
  }

  const page = useMemo(() => {
    return Number(searchParams.get('page') ?? 1)
  }, [searchParams])

  const { data: records, isLoading } = useLog.FindAll(
    page,
    selectedOrder,
    q,
    contrato
  )
  const { data: recordsDated } = useLog.FindAllByData(
    page,
    fixedDate,
    selectedOrder,
    q,
    contrato
  )
  const { data: contratos } = useContrato.ListAll()

  let fullData
  let pagination
  if (dateSearch) {
    fullData = recordsDated?.data ?? []
    pagination = recordsDated?.pagination
  } else {
    fullData = records?.data ?? []
    pagination = records?.pagination
  }

  if (isLoading) return <Loading />

  return (
    <Box>
      <Stack
        component="form"
        onSubmit={handleSearchClick}
        direction="row"
        py={3}
        gap={3}
        mb={2}
      >
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          placeholder="Usuário/Ação/ID da Caixa/ID do Documento"
          inputRef={searchInput}
          autoFocus
          value={searchTerm}
          onChange={(event) => {
            setSearchTerm(event.target.value)
          }}
        />
        <FormControl fullWidth size="small">
          <InputLabel>Contrato</InputLabel>
          <Select
            value={contrato}
            onChange={(event) => setContrato(event.target.value)}
          >
            <MenuItem value="">Todos</MenuItem>
            {contratos?.map((contrato) => (
              <MenuItem key={contrato.id_contrato} value={contrato.id_contrato}>
                {contrato.descricao}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="outlined"
          startIcon={<SearchIcon />}
          type="submit"
        ></Button>
      </Stack>
      <Stack direction="row" mt={-2} mb={2} gap={1}>
        <Input
          label="Data Inicial"
          type="date"
          value={selectedFirstDate}
          onChange={(event) => {
            setSelectedFirstDate(String(event.target.value))
            setSelectedSecondDate(String(event.target.value))
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
        <Input
          label="Data Final"
          type="date"
          value={selectedSecondDate}
          onChange={(event) =>
            setSelectedSecondDate(String(event.target.value))
          }
          InputLabelProps={{
            shrink: true
          }}
        />
        <Button
          variant="contained"
          startIcon={<SearchIcon />}
          onClick={() =>
            handleDateChange(selectedFirstDate + selectedSecondDate)
          }
        ></Button>
      </Stack>

      <Divider />

      <TableContainer
        component={Box}
        border={1}
        borderRadius={2}
        borderColor="#f2f2f2"
      >
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#f2f2f2' }}>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                ID
              </TableCell>
              <TableCell
                sx={{ fontWeight: 'bold', fontSize: '14px', cursor: 'pointer' }}
                onClick={() => {
                  selectedOrder === 'Modulo'
                    ? setSelectedOrder('ModuloInv')
                    : setSelectedOrder('Modulo')
                }}
              >
                Módulo
                {selectedOrder === 'Modulo' ? (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                )}
              </TableCell>
              <TableCell
                sx={{ fontWeight: 'bold', fontSize: '14px', cursor: 'pointer' }}
                onClick={() => {
                  selectedOrder === 'Acao'
                    ? setSelectedOrder('AcaoInv')
                    : setSelectedOrder('Acao')
                }}
              >
                Ação
                {selectedOrder === 'Acao' ? (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                )}
              </TableCell>
              <TableCell
                sx={{ fontWeight: 'bold', fontSize: '14px', cursor: 'pointer' }}
                onClick={() => {
                  selectedOrder === 'Usuario'
                    ? setSelectedOrder('UsuarioInv')
                    : setSelectedOrder('Usuario')
                }}
              >
                Usuário
                {selectedOrder === 'Usuario' ? (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                )}
              </TableCell>
              <TableCell
                sx={{ fontWeight: 'bold', fontSize: '14px', cursor: 'pointer' }}
                onClick={() => {
                  selectedOrder === 'Data'
                    ? setSelectedOrder('DataInv')
                    : setSelectedOrder('Data')
                }}
              >
                Data
                {selectedOrder === 'Data' ? (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                )}
              </TableCell>
              <TableCell width="12px" />
            </TableRow>
          </TableHead>
          <TableBody>
            {fullData.map((record) => (
              <TableRow key={record.id_log} hover>
                <TableCell>{getCodigo(record.id_log)}</TableCell>
                <TableCell>{getModulo(record.acao)}</TableCell>
                <TableCell>{record.acao}</TableCell>
                <TableCell>{record.usuario.pessoa.nome}</TableCell>
                <TableCell>
                  {formata(
                    record.created_at?.toString() ?? '',
                    'dd/MM/yyyy HH:mm'
                  )}
                </TableCell>

                <TableCell>
                  <Tooltip title="Exibir Informações Adicionais" arrow>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        handleShowInfo()
                        setSelectedInfo(record)
                      }}
                      sx={{ minWidth: '24px', padding: '6px' }}
                    >
                      <InfoIcon />
                    </Button>
                  </Tooltip>
                </TableCell>
                {/*<TableCell>
                  <Tooltip
                    title={revealed[record.id_log] ? 'Ocultar' : 'Revelar'}
                    arrow
                  >
                    <Button
                      variant="outlined"
                      sx={{ minWidth: '24px', padding: '6px' }}
                      onClick={() => toggleReveal(record.id_log)}
                    >
                      {revealed[record.id_log] ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </Button>
                  </Tooltip>
                </TableCell>*/}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && pagination.totalItems > pagination.itemsPerPage && (
        <Pagination
          count={pagination.totalPages}
          sx={{ marginTop: '12px' }}
          onChange={(_, newPage) => setSearchParams({ page: String(newPage) })}
          page={pagination.currentPage}
        />
      )}
      <Modal
        open={showInfo}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          top: '20%'
        }}
      >
        <Box
          sx={{
            height: '85%',
            width: '55%',
            padding: '15px',
            bgcolor: 'white',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            overflowY: 'auto'
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginBottom: '15px'
            }}
          >
            <Typography
              sx={{
                marginBottom: '1rem',
                fontSize: '1.5rem',
                fontWeight: 'bold'
              }}
            >
              Informações do Log
            </Typography>
            <TableContainer
              component={Box}
              border={1}
              borderRadius={2}
              borderColor="#f2f2f2"
            >
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: '#f2f2f2' }}>
                    <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                      IP
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                      Username
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                      ID Documento
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                      Data e hora
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={selectedInfo?.id_log} hover>
                    <TableCell>
                      {selectedInfo?.ip.startsWith('::ffff:')
                        ? selectedInfo?.ip.replace('::ffff:', '')
                        : selectedInfo?.ip}
                    </TableCell>
                    <TableCell>{selectedInfo?.usuario?.username}</TableCell>
                    <TableCell>{selectedInfo?.id_documento}</TableCell>
                    {selectedInfo?.created_at?.toString() ? (
                      <TableCell>
                        {formata(
                          selectedInfo?.created_at?.toString() ?? '',
                          'dd/MM/yyyy HH:mm'
                        )}
                      </TableCell>
                    ) : (
                      ''
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {selectedInfo?.acao === 'ALTERAR_DOCUMENTOS' ? (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <Typography
                  sx={{
                    marginBottom: '1rem',
                    marginTop: '1rem',
                    fontSize: '1.5rem',
                    fontWeight: 'bold'
                  }}
                >
                  Alterações Feitas
                </Typography>
                <TableContainer
                  component={Box}
                  border={1}
                  borderRadius={2}
                  borderColor="#f2f2f2"
                >
                  <Table>
                    <TableHead>
                      <TableRow sx={{ backgroundColor: '#f2f2f2' }}>
                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '14px' }}
                        ></TableCell>
                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '14px' }}
                        >
                          ID Documento
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '14px' }}
                        >
                          Descrição
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '14px' }}
                        >
                          Tipo de Documento
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '14px' }}
                        >
                          Assunto
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '14px' }}
                        >
                          Data Final de Guarda
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '14px' }}
                        >
                          Observações
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={selectedInfo?.id_log} hover>
                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '14px' }}
                        >
                          Dados Antigos
                        </TableCell>
                        <TableCell>
                          {selectedInfo?.documento?.id_documento}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight:
                              selectedInfo?.documento?.descricao !==
                              selectedInfo?.documento_log[0]?.descricao
                                ? 'bold'
                                : ''
                          }}
                        >
                          {selectedInfo?.documento?.descricao}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight:
                              selectedInfo?.documento?.id_tipo_documento !==
                              selectedInfo?.documento_log[0]?.id_tipo_documento
                                ? 'bold'
                                : ''
                          }}
                        >
                          {selectedInfo?.documento?.tipo_documento?.codigo}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight:
                              selectedInfo?.documento?.assunto !==
                              selectedInfo?.documento_log[0]?.assunto
                                ? 'bold'
                                : ''
                          }}
                        >
                          {selectedInfo?.documento?.assunto}
                        </TableCell>
                        {selectedInfo?.documento?.data_final ? (
                          <TableCell
                            sx={{
                              fontWeight:
                                selectedInfo?.documento?.data_final !==
                                selectedInfo?.documento_log[0]?.data_final
                                  ? 'bold'
                                  : ''
                            }}
                          >
                            {formata(
                              selectedInfo?.documento?.data_final.toString() ??
                                '',
                              'dd/MM/yyyy HH:mm'
                            )}
                          </TableCell>
                        ) : (
                          <TableCell></TableCell>
                        )}
                        <TableCell
                          sx={{
                            fontWeight:
                              selectedInfo?.documento?.observacao !==
                              selectedInfo?.documento_log[0]?.observacao
                                ? 'bold'
                                : ''
                          }}
                        >
                          {selectedInfo?.documento?.observacao}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                    <TableBody>
                      <TableRow key={selectedInfo?.id_log} hover>
                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '14px' }}
                        >
                          Dados Novos
                        </TableCell>
                        <TableCell>
                          {selectedInfo?.documento?.id_documento}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight:
                              selectedInfo?.documento?.descricao !==
                              selectedInfo?.documento_log[0]?.descricao
                                ? 'bold'
                                : ''
                          }}
                        >
                          {selectedInfo?.documento_log[0]?.descricao}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight:
                              selectedInfo?.documento?.id_tipo_documento !==
                              selectedInfo?.documento_log[0]?.id_tipo_documento
                                ? 'bold'
                                : ''
                          }}
                        >
                          {
                            selectedInfo?.documento_log[0]?.tipoDocumento
                              ?.codigo
                          }
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight:
                              selectedInfo?.documento?.assunto !==
                              selectedInfo?.documento_log[0]?.assunto
                                ? 'bold'
                                : ''
                          }}
                        >
                          {selectedInfo?.documento_log[0]?.assunto}
                        </TableCell>
                        {selectedInfo?.documento_log[0]?.data_final ? (
                          <TableCell
                            sx={{
                              fontWeight:
                                selectedInfo?.documento?.data_final !==
                                selectedInfo?.documento_log[0]?.data_final
                                  ? 'bold'
                                  : ''
                            }}
                          >
                            {formata(
                              selectedInfo?.documento_log[0]?.data_final.toString() ??
                                '',
                              'dd/MM/yyyy HH:mm'
                            )}
                          </TableCell>
                        ) : (
                          <TableCell></TableCell>
                        )}
                        <TableCell
                          sx={{
                            fontWeight:
                              selectedInfo?.documento?.observacao !==
                              selectedInfo?.documento_log[0]?.observacao
                                ? 'bold'
                                : ''
                          }}
                        >
                          {selectedInfo?.documento_log[0]?.observacao}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            ) : (
              ''
            )}
            {selectedInfo?.acao === 'ALTERAR_CAIXA' ? (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <Typography
                  sx={{
                    marginBottom: '1rem',
                    marginTop: '1rem',
                    fontSize: '1.5rem',
                    fontWeight: 'bold'
                  }}
                >
                  Alterações Feitas
                </Typography>
                <TableContainer
                  component={Box}
                  border={1}
                  borderRadius={2}
                  borderColor="#f2f2f2"
                >
                  <Table>
                    <TableHead>
                      <TableRow sx={{ backgroundColor: '#f2f2f2' }}>
                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '14px' }}
                        ></TableCell>
                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '14px' }}
                        >
                          ID Caixa
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '14px' }}
                        >
                          Tipo de Documento
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={selectedInfo?.id_log} hover>
                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '14px' }}
                        >
                          Dados Antigos
                        </TableCell>
                        <TableCell>{selectedInfo?.caixa?.id_caixa}</TableCell>
                        <TableCell
                          sx={{
                            fontWeight:
                              selectedInfo?.caixa?.id_tipo_documento !==
                              selectedInfo?.caixa_log[0]?.id_tipo_documento
                                ? 'bold'
                                : ''
                          }}
                        >
                          {selectedInfo?.caixa?.tipo_documento?.codigo}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                    <TableBody>
                      <TableRow key={selectedInfo?.id_log} hover>
                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '14px' }}
                        >
                          Dados Novos
                        </TableCell>
                        <TableCell>{selectedInfo?.caixa?.id_caixa}</TableCell>
                        <TableCell
                          sx={{
                            fontWeight:
                              selectedInfo?.caixa?.id_tipo_documento !==
                              selectedInfo?.caixa_log[0]?.id_tipo_documento
                                ? 'bold'
                                : ''
                          }}
                        >
                          {selectedInfo?.caixa_log[0]?.tipoDocumento?.codigo}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            ) : (
              ''
            )}
            {selectedInfo?.acao === 'INCLUIR_CAIXA' ? (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <Typography
                  sx={{
                    marginBottom: '1rem',
                    marginTop: '1rem',
                    fontSize: '1.5rem',
                    fontWeight: 'bold'
                  }}
                >
                  Dados Acessados
                </Typography>
                <TableContainer
                  component={Box}
                  border={1}
                  borderRadius={2}
                  borderColor="#f2f2f2"
                >
                  <Table>
                    <TableHead>
                      <TableRow sx={{ backgroundColor: '#f2f2f2' }}>
                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '14px' }}
                        >
                          ID Caixa
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '14px' }}
                        >
                          Identificação
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '14px' }}
                        >
                          Conteúdo
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '14px' }}
                        >
                          Data Inicial
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '14px' }}
                        >
                          Data Final
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '14px' }}
                        >
                          Sequência Inicial
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '14px' }}
                        >
                          Sequência Final
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '14px' }}
                        >
                          Privacidade
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '14px' }}
                        >
                          Contrato
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '14px' }}
                        >
                          Setor
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '14px' }}
                        >
                          Tipo de Documento
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '14px' }}
                        >
                          Tipo de Caixas
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={selectedInfo?.id_log} hover>
                        <TableCell>{selectedInfo?.caixa?.id_caixa}</TableCell>
                        <TableCell>
                          {selectedInfo?.caixa?.identificacao}
                        </TableCell>
                        <TableCell>{selectedInfo?.caixa?.conteudo}</TableCell>
                        {selectedInfo?.caixa?.data_inicio ? (
                          <TableCell>
                            {formata(
                              selectedInfo?.caixa?.data_inicio.toString() ?? '',
                              'dd/MM/yyyy HH:mm'
                            )}
                          </TableCell>
                        ) : (
                          <TableCell></TableCell>
                        )}
                        {selectedInfo?.caixa?.data_fim ? (
                          <TableCell>
                            {formata(
                              selectedInfo?.caixa?.data_fim.toString() ?? '',
                              'dd/MM/yyyy HH:mm'
                            )}
                          </TableCell>
                        ) : (
                          <TableCell></TableCell>
                        )}
                        <TableCell>
                          {selectedInfo?.caixa?.sequencia_inicio}
                        </TableCell>
                        <TableCell>
                          {selectedInfo?.caixa?.sequencia_fim}
                        </TableCell>
                        <TableCell>
                          {' '}
                          {selectedInfo?.caixa?.dados_pessoais ? (
                            <span>
                              [<strong>{' P '}</strong>]
                            </span>
                          ) : null}
                          {selectedInfo?.caixa?.dados_pessoais_sensiveis ? (
                            <span>
                              [<strong>{' S '}</strong>]
                            </span>
                          ) : null}
                          {selectedInfo?.caixa?.dados_criancas_adolescentes ? (
                            <span>
                              [<strong>{' C '}</strong>]
                            </span>
                          ) : null}
                        </TableCell>
                        <TableCell>
                          {selectedInfo?.caixa?.contrato?.descricao}
                        </TableCell>
                        <TableCell>
                          {selectedInfo?.caixa?.setor?.nome}
                        </TableCell>
                        <TableCell>
                          {selectedInfo?.caixa?.tipo_documento?.codigo}
                        </TableCell>
                        <TableCell>
                          {selectedInfo?.caixa?.tipoCaixa?.descricao}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            ) : (
              ''
            )}
            {selectedInfo?.acao === 'INCLUIR_DOCUMENTOS' ? (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <Typography
                  sx={{
                    marginBottom: '1rem',
                    marginTop: '1rem',
                    fontSize: '1.5rem',
                    fontWeight: 'bold'
                  }}
                >
                  Dados Acessados
                </Typography>
                <TableContainer
                  component={Box}
                  border={1}
                  borderRadius={2}
                  borderColor="#f2f2f2"
                >
                  <Table>
                    <TableHead>
                      <TableRow sx={{ backgroundColor: '#f2f2f2' }}>
                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '14px' }}
                        >
                          ID Documento
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '14px' }}
                        >
                          Contrato
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '14px' }}
                        >
                          Tipo de Documento
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '14px' }}
                        >
                          Descrição
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '14px' }}
                        >
                          Assunto
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '14px' }}
                        >
                          Data Final de Guarda
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: 'bold', fontSize: '14px' }}
                        >
                          Observações
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={selectedInfo?.id_log} hover>
                        <TableCell>
                          {selectedInfo?.documento?.id_documento}
                        </TableCell>
                        <TableCell>
                          {selectedInfo?.documento?.contrato?.descricao}
                        </TableCell>
                        <TableCell>
                          {selectedInfo?.documento?.tipo_documento?.codigo}
                        </TableCell>
                        <TableCell>
                          {selectedInfo?.documento?.descricao}
                        </TableCell>
                        <TableCell>
                          {selectedInfo?.documento?.assunto}
                        </TableCell>
                        {selectedInfo?.documento?.data_final ? (
                          <TableCell></TableCell>
                        ) : (
                          <TableCell></TableCell>
                        )}
                        <TableCell>
                          {selectedInfo?.documento?.observacao}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            ) : (
              ''
            )}
          </Box>
          <Button
            onClick={() => setShowInfo(false)}
            variant="outlined"
            sx={{ width: '10rem' }}
          >
            Fechar
          </Button>
        </Box>
      </Modal>
    </Box>
  )
}
