import {
  History,
  MoreHoriz,
  ManageSearch,
  BuildCircle,
  CheckCircle,
  Close,
  DoneAll,
  DoNotDisturbAltOutlined
} from '@mui/icons-material'

import {
  IDashboard,
  ITipoAtividade
} from '../../../../services/dashboard/types'
import { ReactNode, useEffect, useMemo, useState } from 'react'

import { DashboardWidgetSummary } from '../../../../types/DashboardWidget/DashboardWidgetSummary'
import { DashboardWidgetSummaryBody } from '../../../../types/DashboardWidget/DashboardWidgetSummary/DashboardWidgetSummaryBody'
import { DashboardWidgetSummaryHeader } from '../../../../types/DashboardWidget/DashboardWidgetSummary/DashboardWidgetSummaryHeader'
import { DashboadWidgetSummaryHeaderToolbar } from '../../../../types/DashboardWidget/DashboardWidgetSummary/DashboardWidgetSummaryHeader/DashboadWidgetSummaryHeaderToolbar/index.tsx'
import { DashboardWidgetSummaryHeaderDatabar } from '../../../../types/DashboardWidget/DashboardWidgetSummary/DashboardWidgetSummaryHeader/DashboardWidgetSummaryHeaderDatabar'
import { processData } from './process'
import { TWidgetState } from '../../../../types/DashboardWidget/WidgetState'
import { DashboardWidget } from '../../../../types/DashboardWidget'
import { DashboardWidgetList } from '../../../../types/DashboardWidget/DashboardWidgetList'
import { DashboardWidgetListHeader } from '../../../../types/DashboardWidget/DashboardWidgetList/DashboardWidgetListHeader'
import { DashboardWidgetListBody } from '../../../../types/DashboardWidget/DashboardWidgetList/DashboardWidgetListBody'
import { ReactComponent as CloseButton } from '../../../../assets/images/botaoFechar.svg'
import { getCodigo } from '../../../../utils/getCodigo'
import { formata } from '../../../../utils/formataData'
import { IMovimentacaoExtendida } from '../../../../services/movimentacao/types'
import { DashboardWidgetForm } from '../../../../types/DashboardWidget/DashboardWidgetForm'
import { cloneData } from '../../../../utils/cloneData'
import { isEqual } from '../../../../utils/isEqual'
import { useMovimentacao } from '../../../../hooks/movimentacao/useMovimentacao'
import useToast from '../../../../hooks/toast/useToast'
import { simplifyMovimentacao } from '../../../../utils/simplifyMovimentacao'
import { corFundoLinha, defaultWidgetSummaryStyle } from '../lib/consts'
import { BoxStatus, StatusBox } from '../../../../components/StatusBox'
import { corTextoLinha } from '../../../../types/DashboardWidget/cores'
import { TWidgetAtividade } from '../lib/@types/TWidgetAtividade'
import { useUsuario } from '../../../../hooks/usuario/useUsuario'
import { Loading } from '../../../../components/Loading'
import { Checkbox, FormControlLabel } from '@mui/material'

interface WidgetTabelaProps {
  data: IDashboard
  tipoAtividade: ITipoAtividade
  onStateChange: (tipoAtividade: TWidgetAtividade) => void
}

const getStatus = (movimentacao: IMovimentacaoExtendida): ReactNode => {
  let status: BoxStatus = 'Ok'
  let text = ''
  if (movimentacao.atraso === 0) {
    if (movimentacao.id_usuario) {
      text = 'Executando'
    } else {
      status = 'Info'
      text = 'Pendente'
    }
  } else {
    if (movimentacao.id_usuario) {
      status = 'Warning'
      text = 'Por finalizar'
    } else {
      status = 'Error'
      text = 'Por executar'
    }
  }
  return <StatusBox caption={text} status={status} />
}

export function WidgetTabela({
  data,
  tipoAtividade,
  onStateChange
}: WidgetTabelaProps) {
  //states da paginação
  const [currentPage, setCurrentPage] = useState(1)
  const [pageData, setPageData] = useState<IMovimentacaoExtendida[]>([])
  // fim states da paginação

  const { data: usuario, isLoading } = useUsuario.FindByUsername()
  const permissoes = usuario?.permissoes as Array<number>
  const hasAccess = (permission: number) => permissoes.includes(permission)
  const widgetSummaryHeight = '400px'
  const widgetSummaryWidth = '400px'
  const widgetOtherWidth = '100%'
  const widgetOtherHeight = '740px'
  const { Toast, ToastError } = useToast()
  const { mutateAsync: updateMovimentacao } = useMovimentacao.Update()
  const { mutateAsync: updateManyMovimentacao } = useMovimentacao.UpdateMany()
  const processedData = useMemo(
    () => processData(data, tipoAtividade),
    [data, tipoAtividade]
  )
  const [movimentacaoAtual, setMovimentacaoAtual] =
    useState<IMovimentacaoExtendida>()
  const [movimentacaoInicial, setMovimentacaoInicial] =
    useState<IMovimentacaoExtendida>()
  const [movimentacaoModificada, setMovimentacaoModificada] =
    useState<boolean>(false)

  const [widgetState, setWidgetState] = useState<TWidgetState>('SUMMARY')
  const [maxWidgetHeight, setMaxWidgetHeight] =
    useState<string>(widgetSummaryHeight)
  const [maxWidgetWidth, setMaxWidgetWidth] =
    useState<string>(widgetSummaryWidth)

  const [itensSelecionados, setItensSelecionados] = useState<
    IMovimentacaoExtendida[]
  >([])
  const [itemSelecionadoEstado, setItemSelecionadoEstado] = useState<string>('')
  const [isAtualizandoMovimentos, setisAtualizandoMovimentos] = useState(false)
  const [marcarTodos, setMarcarTodos] = useState<boolean>(false)
  const temEstadoPendente = processedData.tableData.some(
    (mov) => mov.estado === 'PENDENTE'
  )
  const temEstadoExecutando = processedData.tableData.some(
    (mov) => mov.estado === 'EXECUTANDO'
  )

  const handleItemSelection = (item: IMovimentacaoExtendida) => {
    const id_movimentacao = item.id_movimentacao ?? ''
    const itemIndex = itensSelecionados.findIndex(
      (mov) => mov.id_movimentacao === id_movimentacao
    )
    if (itemIndex !== -1) {
      // Se estiver, remove-o da lista
      const novosItensSelecionados = [...itensSelecionados]
      novosItensSelecionados.splice(itemIndex, 1)
      setItensSelecionados(novosItensSelecionados)
      if (novosItensSelecionados.length === 0) {
        setItemSelecionadoEstado('')
      }
    } else {
      // Se não estiver, adiciona-o à lista
      if (
        item.estado === itemSelecionadoEstado ||
        itemSelecionadoEstado === ''
      ) {
        if (itemSelecionadoEstado === '') {
          setItemSelecionadoEstado(item.estado)
        }

        setItensSelecionados([...itensSelecionados, item])
      } else {
        ToastError(
          `O estado do item não é igual ao dos demais itens selecionados. Atual ${itemSelecionadoEstado} : ${item.estado}`
        )
      }
    }
  }

  const handleMarcarTodos = () => {
    if (marcarTodos) {
      setItensSelecionados([])
      setMarcarTodos(false)
      setItemSelecionadoEstado('')
    } else {
      setMarcarTodos(true)
      setItensSelecionados(processedData.tableData)
      setItemSelecionadoEstado(processedData.tableData[0].estado)
    }
  }

  const handleMoreClick = (): void => {
    setWidgetState('LIST')
    setMaxWidgetHeight(widgetOtherHeight)
    setMaxWidgetWidth(widgetOtherWidth)
    onStateChange(tipoAtividade)
  }

  const handleCloseListClick = (): void => {
    setWidgetState('SUMMARY')
    setMaxWidgetHeight(widgetSummaryHeight)
    setMaxWidgetWidth(widgetSummaryWidth)
    onStateChange(undefined)
  }

  const handleViewItemClick = (id: string): void => {
    setWidgetState('FORM')
    setMaxWidgetHeight(widgetOtherHeight)
    setMaxWidgetWidth(widgetOtherWidth)
    const newMovimentacaoAtual = processedData.tableData.filter(
      (mov) => mov.id_movimentacao === id
    )

    if (newMovimentacaoAtual.length > 0) {
      setMovimentacaoAtual(
        cloneData<IMovimentacaoExtendida>(newMovimentacaoAtual.at(0)!)
      )
      setMovimentacaoInicial(
        cloneData<IMovimentacaoExtendida>(newMovimentacaoAtual.at(0)!)
      )
      setMovimentacaoModificada(false)
    }
    onStateChange(tipoAtividade)
  }

  const handleCloseFormClick = (): void => {
    setWidgetState('LIST')
    setMaxWidgetHeight(widgetOtherHeight)
    setMaxWidgetWidth(widgetOtherWidth)
  }

  const handleFormDataChange = (movimentacao: IMovimentacaoExtendida): void => {
    setMovimentacaoAtual(movimentacao)
    setMovimentacaoModificada(!isEqual(movimentacao, movimentacaoInicial))
  }

  const handleSaveClick = async () => {
    if (movimentacaoModificada) {
      await Toast(
        updateMovimentacao(simplifyMovimentacao(movimentacaoAtual!)),
        'UPDATED'
      )
      setMovimentacaoInicial(movimentacaoAtual)
      setMovimentacaoModificada(false)
    }
  }

  const handleMovimentacaoUpdateState = async (
    novoEstado: 'EXECUTANDO' | 'COMPLETO' | 'CANCELADO'
  ) => {
    await Toast(
      updateMovimentacao(
        simplifyMovimentacao({ ...movimentacaoAtual!, estado: novoEstado })
      ),
      'UPDATED'
    )
    setWidgetState('LIST')
  }

  const handleUpdateManySuccess = () => {
    if (itemSelecionadoEstado === 'EXECUTANDO') {
      setItensSelecionados([])
      setItemSelecionadoEstado('')
    } else if (itemSelecionadoEstado === 'PENDENTE') {
      setItemSelecionadoEstado('EXECUTANDO')
    }
    setisAtualizandoMovimentos(false)
  }
  const handleUpdateManySuccessCancelMovimentacao = () => {
    setItensSelecionados([])
    setItemSelecionadoEstado('')
    setisAtualizandoMovimentos(false)
  }

  const handleUpdateManyError = () => {
    setisAtualizandoMovimentos(false)
  }

  const handleUpdateManyState = async (estadoAcao = '') => {
    setisAtualizandoMovimentos(true)
    const novoEstado =
      estadoAcao === ''
        ? itemSelecionadoEstado === 'PENDENTE'
          ? 'EXECUTANDO'
          : 'COMPLETO'
        : estadoAcao
    const movimentacoesSimplificadas = itensSelecionados.map((mov) =>
      simplifyMovimentacao({ ...mov, estado: novoEstado })
    )

    await Toast(
      updateManyMovimentacao(movimentacoesSimplificadas),
      'UPDATED',
      'Movimentações Atualizadas com sucesso',
      estadoAcao === 'CANCELADO'
        ? handleUpdateManySuccessCancelMovimentacao
        : handleUpdateManySuccess,
      handleUpdateManyError
    )
  }

  const chartData = {
    labels: processedData.summaryData.chartData.map((dt) => dt.descricao),
    datasets: [
      {
        label: 'Total de Itens',
        data: processedData.summaryData.chartData.map((dt) => dt.quantidade),
        backgroundColor: ['rgb(233, 44, 44)', '#219653'],
        hoverOffset: 4
      }
    ]
  }
  const colunasLista = [
    {
      title: '',
      width: 50,
      component: (
        <Checkbox
          onClick={handleMarcarTodos}
          checked={marcarTodos}
          disabled={temEstadoExecutando && temEstadoPendente}
        />
      )
    },
    {
      title: 'Código da Requisição'
    },
    {
      title: 'Contrato'
    },
    {
      title: 'Requisitante'
    },
    {
      title: 'Tipo'
    },
    {
      title: 'Item'
    },
    {
      title: 'Iniciado'
    },
    {
      title: 'SLA (horas)'
    },
    {
      title: 'Atraso (horas)'
    },
    {
      title: 'Estado Execução'
    },
    {
      title: '',
      width: 50
    }
  ]

  // função e constantes da paginação
  const itemsPerPage = 10
  const totalItems = processedData.tableData.length
  const totalPages = Math.ceil(totalItems / itemsPerPage)

  const onPageChange = (page: number) => {
    setCurrentPage(page)
    const start = (page - 1) * itemsPerPage
    const end = start + itemsPerPage
    setPageData(processedData.tableData.slice(start, end))
  }
  //toda vez que os dados forem alterados ele atualiza a paginação
  useEffect(() => {
    const page = currentPage > totalPages ? totalPages : currentPage
    onPageChange(page)
  }, [data])

  if (isLoading) <Loading />
  return (
    <>
      {widgetState === 'SUMMARY' && (
        <DashboardWidget.Root
          sx={{
            borderRadius: '20px',
            boxShadow:
              '0px 8px 16px -4px rgba(24, 39, 75, 0.08), 0px 6px 0px -6px rgba(0, 0, 0, 0.25)',
            backgroundColor: '#fff',
            margin: '2rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1rem',
            maxHeight: maxWidgetHeight,
            maxWidth: maxWidgetWidth
          }}
        >
          <DashboardWidgetSummary.Root sx={{ ...defaultWidgetSummaryStyle }}>
            <DashboardWidgetSummaryHeader.Root>
              <DashboadWidgetSummaryHeaderToolbar.Root>
                <DashboadWidgetSummaryHeaderToolbar.Type
                  type={tipoAtividade.identificacao}
                />
                <DashboadWidgetSummaryHeaderToolbar.Action
                  icon={MoreHoriz}
                  onClick={handleMoreClick}
                />
              </DashboadWidgetSummaryHeaderToolbar.Root>
              <DashboardWidgetSummaryHeader.Title
                title={processedData.summaryData.totalItens.toString()}
              />
              <DashboardWidgetSummaryHeaderDatabar.Root>
                {processedData.summaryData.headerData.map((dt, index) => (
                  <DashboardWidgetSummaryHeaderDatabar.Data
                    key={`wRequisicoesData${index}`}
                    data={Math.floor(dt.quantidade).toString()}
                    title={dt.descricao}
                  />
                ))}
              </DashboardWidgetSummaryHeaderDatabar.Root>
            </DashboardWidgetSummaryHeader.Root>
            <DashboardWidgetSummaryBody.Root>
              <DashboardWidgetSummaryBody.Pie data={chartData} />
            </DashboardWidgetSummaryBody.Root>
          </DashboardWidgetSummary.Root>
        </DashboardWidget.Root>
      )}

      {widgetState === 'LIST' && (
        <DashboardWidget.Root
          sx={{
            borderRadius: '20px',
            boxShadow:
              '0px 8px 16px -4px rgba(24, 39, 75, 0.08), 0px 6px 0px -6px rgba(0, 0, 0, 0.25)',
            backgroundColor: '#fff',
            margin: '2rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1rem',
            maxHeight: maxWidgetHeight,
            maxWidth: maxWidgetWidth
          }}
        >
          <DashboardWidgetList.Root
            sx={{
              width: maxWidgetWidth,
              height: maxWidgetHeight,
              margin: 0
            }}
          >
            <DashboardWidgetListHeader.Root title={tipoAtividade.identificacao}>
              {itensSelecionados.length > 0 &&
                hasAccess(
                  tipoAtividade.permissao_movimentacao.codigo_permissao
                ) &&
                (tipoAtividade.identificacao ===
                  'Cancelada Realocação (Interno)' ||
                tipoAtividade.identificacao ===
                  'Cancelar Pedido de Requisição' ? (
                  ''
                ) : (
                  <DashboardWidgetForm.Action
                    icon={DoNotDisturbAltOutlined}
                    title="Cancelar Movimentação"
                    tip="Passar a Movimentação dos itens marcados para o estado de CANCELADA"
                    color="error"
                    variant="contained"
                    onClick={() => handleUpdateManyState('CANCELADO')}
                    disabled={isAtualizandoMovimentos}
                  />
                ))}
              {itensSelecionados.length > 0 &&
                hasAccess(
                  tipoAtividade.permissao_movimentacao.codigo_permissao
                ) && (
                  <DashboardWidgetForm.Action
                    tip={
                      itemSelecionadoEstado === 'PENDENTE'
                        ? 'Executar Atividade'
                        : 'Finalizar Execução de Atividade'
                    }
                    title={
                      itemSelecionadoEstado === 'PENDENTE'
                        ? 'Executar'
                        : 'Finalizar'
                    }
                    icon={
                      itemSelecionadoEstado === 'PENDENTE'
                        ? BuildCircle
                        : DoneAll
                    }
                    onClick={
                      itemSelecionadoEstado === 'PENDENTE'
                        ? () => handleUpdateManyState()
                        : () => handleUpdateManyState()
                    }
                    variant="contained"
                    color="info"
                    disabled={isAtualizandoMovimentos}
                  />
                )}
              <DashboardWidgetListHeader.Data
                total={processedData.summaryData.totalItens}
                icon={History}
              />
              <DashboardWidgetListHeader.Actions>
                <DashboardWidgetListHeader.IconAction
                  icon={CloseButton}
                  onClick={handleCloseListClick}
                  tip={'Voltar para Dashboard'}
                  style={{
                    width: '40px',
                    height: '40px',
                    padding: 0,
                    margin: 0,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    borderRadius: '20px',
                    border: 0
                  }}
                />
              </DashboardWidgetListHeader.Actions>
            </DashboardWidgetListHeader.Root>
            <DashboardWidgetListBody.Root>
              <DashboardWidgetListBody.Table
                // title={tipoAtividade.descricao}
                columnsDefs={colunasLista}
              >
                {/*processedData.tableData*/}
                {pageData.map((movimentacao, idx) => {
                  const values = [
                    <FormControlLabel
                      control={
                        <Checkbox
                          onClick={() => {
                            handleItemSelection(movimentacao)
                          }}
                          checked={
                            marcarTodos ||
                            itensSelecionados.some(
                              (item) =>
                                item.id_movimentacao ===
                                movimentacao.id_movimentacao
                            )
                          }
                        />
                      }
                      label=""
                    />,
                    getCodigo(
                      movimentacao.item_requisicao?.requisicao?.id_requisicao ??
                        ''
                    ),
                    movimentacao.item_requisicao?.requisicao?.item_contrato
                      ?.contrato?.descricao,
                    movimentacao.item_requisicao?.requisicao?.usuario?.pessoa
                      ?.nome ?? '',
                    movimentacao.tipoItem,
                    movimentacao?.item_requisicao?.caixa?.identificacao ??
                      movimentacao?.item_requisicao?.documento?.descricao,
                    formata(
                      new Date(movimentacao.data_inicio),
                      "dd/MM/yyyy 'às' HH:mm"
                    ),
                    (movimentacao?.preco_atividade?.sla ?? 0).toString(),
                    movimentacao?.atraso,
                    getStatus(movimentacao)
                  ]
                  return (
                    <DashboardWidgetListBody.Row
                      sx={{
                        backgroundColor: idx % 2 === 0 ? corFundoLinha : ''
                      }}
                      cellStyle={{
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        fontSize: '1rem',
                        color: corTextoLinha,
                        paddingBottom: 0,
                        paddingTop: 0,
                        borderBottom: 0,
                        height: '40px'
                      }}
                      values={values}
                      key={
                        movimentacao.id_item_requisicao ??
                        '' + movimentacao.id_movimentacao ??
                        ''
                      }
                    >
                      <DashboardWidgetListBody.Actions>
                        <DashboardWidgetListBody.Action
                          tip="Atendimento da Movimentação"
                          icon={ManageSearch}
                          color="primary"
                          sx={{ minWidth: '24px', padding: '6px' }}
                          onClick={() => {
                            handleViewItemClick(
                              movimentacao.id_movimentacao ?? ''
                            )
                          }}
                        />
                      </DashboardWidgetListBody.Actions>
                    </DashboardWidgetListBody.Row>
                  )
                })}
              </DashboardWidgetListBody.Table>
              {itemsPerPage < totalItems && (
                <DashboardWidgetListBody.Pagination
                  itemsPerPage={itemsPerPage}
                  currentPage={currentPage}
                  totalItems={totalItems}
                  totalPages={totalPages}
                  onPageChange={onPageChange}
                />
              )}
            </DashboardWidgetListBody.Root>
          </DashboardWidgetList.Root>
        </DashboardWidget.Root>
      )}
      {widgetState === 'FORM' && (
        <DashboardWidget.Root
          sx={{
            borderRadius: '20px',
            boxShadow:
              '0px 8px 16px -4px rgba(24, 39, 75, 0.08), 0px 6px 0px -6px rgba(0, 0, 0, 0.25)',
            backgroundColor: '#fff',
            margin: '2rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1rem',
            maxHeight: maxWidgetHeight,
            maxWidth: maxWidgetWidth
          }}
        >
          <DashboardWidgetForm.Root
            sx={{ width: maxWidgetWidth, height: maxWidgetHeight }}
          >
            <DashboardWidgetForm.Header title={tipoAtividade.identificacao}>
              <DashboardWidgetForm.Actions>
                {hasAccess(
                  tipoAtividade.permissao_movimentacao.codigo_permissao
                ) &&
                  (tipoAtividade.identificacao ===
                    'Cancelada Realocação (Interno)' ||
                  tipoAtividade.identificacao ===
                    'Cancelar Pedido de Requisição' ? (
                    ''
                  ) : (
                    <DashboardWidgetForm.Action
                      icon={DoNotDisturbAltOutlined}
                      title="Cancelar Movimentação"
                      tip="Passar a Movimentação para o estado de CANCELADA"
                      color="error"
                      variant="contained"
                      onClick={() => handleMovimentacaoUpdateState('CANCELADO')}
                    />
                  ))}
                {hasAccess(
                  tipoAtividade.permissao_movimentacao.codigo_permissao
                ) && (
                  <DashboardWidgetForm.Action
                    tip={
                      movimentacaoAtual?.estado === 'PENDENTE'
                        ? 'Executar Atividade'
                        : 'Finalizar Execução de Atividade'
                    }
                    title={
                      movimentacaoAtual?.estado === 'PENDENTE'
                        ? 'Executar'
                        : 'Finalizar'
                    }
                    icon={
                      movimentacaoAtual?.estado === 'PENDENTE'
                        ? BuildCircle
                        : DoneAll
                    }
                    onClick={
                      movimentacaoAtual?.estado === 'PENDENTE'
                        ? () => handleMovimentacaoUpdateState('EXECUTANDO')
                        : () => handleMovimentacaoUpdateState('COMPLETO')
                    }
                    variant="contained"
                    color="info"
                  />
                )}
                {hasAccess(
                  tipoAtividade.permissao_movimentacao.codigo_permissao
                ) && (
                  <DashboardWidgetForm.Action
                    tip="Salvar as modificações feitas à atividade"
                    title="Salvar"
                    variant="contained"
                    color="success"
                    icon={CheckCircle}
                    onClick={() => handleSaveClick()}
                    disabled={!movimentacaoModificada}
                  />
                )}
                <DashboardWidgetForm.Action
                  tip="Voltar para tela anterior sem salvar modificações"
                  title="Cancelar"
                  icon={Close}
                  variant="outlined"
                  color="secondary"
                  sx={{ gap: 1, color: '#1C1C1C', fontWeight: 'bold' }}
                  onClick={() => handleCloseFormClick()}
                />
              </DashboardWidgetForm.Actions>
            </DashboardWidgetForm.Header>
            {!!movimentacaoAtual && (
              <DashboardWidgetForm.Movimentacao
                data={movimentacaoAtual}
                onDataChange={handleFormDataChange}
              />
            )}
          </DashboardWidgetForm.Root>
        </DashboardWidget.Root>
      )}
    </>
  )
}
