import { DefaultApi } from '../../../utils/defaultApi'
import api from '../../api'
import { IRecorrencia } from './types'

const endpoint = '/financeiro/recorrencias'
const resourceId = 'id_recorrencia'

class ServiceRecorrencia extends DefaultApi<IRecorrencia> {
  gerarLancamentos = async (id_recorrencia: string) => {
    const { data } = await api.post<IRecorrencia[]>(
      `${endpoint}/gerar/${id_recorrencia}`
    )
    return data
  }

  cancelar = async (id_recorrencia: string) => {
    const { data } = await api.put<IRecorrencia[]>(
      `${endpoint}/cancelar/${id_recorrencia}`
    )
    return data
  }
}

const apiRecorrencia = new ServiceRecorrencia(endpoint, resourceId)

export const ApiRecorrencia = {
  ...apiRecorrencia,
  gerarLancamentos: apiRecorrencia.gerarLancamentos,
  cancelar: apiRecorrencia.cancelar
}
