import { useState, useEffect, useRef } from 'react'
import {
  Box,
  Button,
  TextField,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'
import { Link } from 'react-router-dom'
import SendIcon from '@mui/icons-material/Send'
import CachedIcon from '@mui/icons-material/Cached'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { FormTitle } from '../../../components/Form/FormTitle'
import { useMensagem } from '../../../hooks/mensagem/useMensagem'
import { useUsuario } from '../../../hooks/usuario/useUsuario'
import { useRequisicao } from '../../../hooks/requisicao/useRequisicao'
import { IRequisicao } from '../../../services/requisicao/types'
import api from '../../../services/api'
import { getCodigo } from '../../../utils/getCodigo'
import { ShowMessage } from '../../../components/ShowMessage'
import UsernameDisplay from './components/UsernameDisplay'

export function Mensagens() {
  const [mensagem, setMensagem] = useState<string>()
  const [errorMessage, setErrorMessage] = useState<string>()
  const parentRef = useRef<HTMLDivElement>(null)
  const [parentWidth, setParentWidth] = useState<number>(1)

  const localData = localStorage.getItem('@easydoc:user')
  let idData: any
  if (localData) {
    idData = JSON.parse(localData)
  }
  const req_id = localStorage.getItem('@easydoc:req_id')
  let reqData: any
  if (req_id) {
    reqData = req_id
  }
  const id_destinatario = localStorage.getItem('@easydoc:req_user')
  let idDest: any
  if (id_destinatario) {
    idDest = id_destinatario
  }

  async function submitMessage(event: React.FormEvent<HTMLInputElement>) {
    event.preventDefault()

    try {
      await api.post('/mensagem', {
        id_remetente: idData.id_usuario,
        id_destinatario: idDest,
        id_requisicao: reqData,
        mensagem: mensagem
      })
    } catch (err: any) {
      setErrorMessage(err?.message)
    }
    setMensagem('')
    window.location.reload()
  }

  const { data: records } = useMensagem.findByRequisicao(reqData)
  const { data: userName } = useUsuario.FindByUsername()
  const { data: reqStatus } = useRequisicao.FindOne(reqData)

  let fullData
  if (records) {
    fullData = [...records]
    fullData.sort(
      (a, b) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
    )
  }

  async function updateUnread(id_mensagem: string) {
    try {
      await api.put(`/mensagem/${id_mensagem}`, { lida: true })
    } catch (err: any) {
      setErrorMessage(err?.message)
    }
  }

  if (idData.id_usuario === idDest) {
    fullData?.map(async (obj) => {
      if (obj.id_remetente !== userName?.id_usuario) {
        await updateUnread(obj.id_mensagem)
      }
    })
  }

  if (userName?.pessoa.setor.empresa?.nome === 'X-SOLUTION') {
    fullData?.map(async (obj) => {
      if (obj.id_remetente === idDest) {
        await updateUnread(obj.id_mensagem)
      }
    })
  }

  function dateFormat(dataHoraString: string) {
    dataHoraString = dataHoraString.slice(1)

    const [dataPart, horaPart] = dataHoraString.split('T')
    const [ano, mes, dia] = dataPart.split('-')
    // eslint-disable-next-line prefer-const
    let [hora, minutos] = horaPart.slice(0, -5).split(':')

    if (Number(hora) < 3) {
      hora = String(Number(hora) + 21)
    } else hora = String(Number(hora) - 3)

    const dataFormatada = `${dia}-${mes}-${ano}`
    const horaFormatada = `${hora.padStart(2, '0')}:${minutos.padStart(2, '0')}`

    return `${dataFormatada} / ${horaFormatada}`
  }

  const getEstadoExecucao = (requisicao: IRequisicao): string => {
    if (!requisicao.itens_requisicao) return 'COMPLETO'

    const todasMovimentacoesCompleto = requisicao.itens_requisicao.some(
      (item) =>
        item.movimentacoes.some(
          (movimentacao) =>
            movimentacao.estado === 'PENDENTE' ||
            movimentacao.estado === 'EXECUTANDO'
        )
    )

    return todasMovimentacoesCompleto ? 'EXECUTANDO/PENDENTE' : 'COMPLETO'
  }

  const estadoExecucao = reqStatus ? getEstadoExecucao(reqStatus) : ''

  useEffect(() => {
    const parentElement = parentRef.current
    if (parentElement) {
      const width = parentElement.getBoundingClientRect().width
      setParentWidth(width)
    }
  }, [parentRef])

  const messagesEndRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    scrollToBottom()
  }, [fullData])

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <>
      <Box
        sx={{
          padding: '15px',
          background: '#fff',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <FormTitle
          title={`Mensagem (Requisição: ${getCodigo(reqData ?? '')})`}
        />
        <Box>
          <Link to="/gerenciar-solicitacoes/status-solicitacoes">
            <Tooltip title="voltar">
              <Button variant="contained">
                <ArrowBackIcon sx={{ minWidth: '12px' }} />
              </Button>
            </Tooltip>
          </Link>
        </Box>
      </Box>
      <Box
        ref={parentRef}
        bgcolor="#fff"
        p={4}
        borderRadius={2}
        sx={{
          overflowY: 'auto',
          height: '70vh',
          position: 'relative'
        }}
      >
        <Stack gap={5} mb={3} mt={3} sx={{ zIndex: 0 }}>
          <Stack gap={2} sx={{ position: 'relative' }}>
            {fullData?.map((obj) => (
              <>
                <Typography
                  sx={{
                    marginTop: '10px',
                    marginBottom: '-15px',
                    alignSelf:
                      obj.id_remetente === idData.id_usuario
                        ? 'flex-end'
                        : 'flex-start'
                  }}
                >
                  {obj.id_remetente === idData.id_usuario ? (
                    <Typography>{userName?.username}</Typography>
                  ) : (
                    <Typography>
                      <UsernameDisplay userId={obj.id_remetente} />
                    </Typography>
                  )}
                </Typography>
                <Typography
                  key={obj.id_mensagem}
                  sx={{
                    fontSize: '17px',
                    bgcolor:
                      obj.id_remetente === idData.id_usuario
                        ? '#2835ad'
                        : '#626ff5',
                    color: '#fff',
                    maxWidth: '70%',
                    borderRadius: '10px',
                    padding: '10px',
                    alignSelf:
                      obj.id_remetente === idData.id_usuario
                        ? 'flex-end'
                        : 'flex-start'
                  }}
                >
                  {obj.mensagem}
                  <Typography
                    sx={{
                      fontSize: '11px',
                      color: '#aeaeb0',
                      textAlign: 'right'
                    }}
                  >
                    {dateFormat(JSON.stringify(obj.created_at))}
                  </Typography>
                </Typography>
              </>
            ))}
            <div ref={messagesEndRef}></div>
          </Stack>
        </Stack>

        <Box
          component="form"
          onSubmit={submitMessage}
          sx={{
            width: `${parentWidth}px`,
            display: 'flex',
            position: 'fixed',
            bottom: 10,
            left: 325,
            zIndex: 1
          }}
        >
          <TextField
            sx={{ bgcolor: '#fff' }}
            placeholder="Mensagem"
            required
            fullWidth
            autoFocus
            autoComplete="off"
            value={mensagem}
            onChange={(e) => setMensagem(e.target.value)}
          />
          <Button
            variant="contained"
            type="submit"
            disabled={estadoExecucao === 'COMPLETO' ? true : false}
            sx={{ height: '40px', ml: 2, mt: 0.7 }}
          >
            <SendIcon sx={{ color: '#fff' }} />
          </Button>
          <Button
            variant="outlined"
            sx={{ height: '40px', ml: 2, mt: 0.7, bgcolor: '#fff' }}
            onClick={() => window.location.reload()}
          >
            <CachedIcon />
          </Button>
        </Box>
        <ShowMessage
          hasError={!!errorMessage}
          message={errorMessage}
          onClose={() => setErrorMessage(undefined)}
        />
      </Box>
    </>
  )
}
