import { DefaultApi } from '../../../utils/defaultApi'
import { IResult, TAtivo } from '../../../utils/defaultApi/types'
import api from '../../api'
import { IBaixaLancamento, ILancamento } from './types'

const endpoint = '/financeiro/lancamentos'
const resourceId = 'id_lancamento'

class ServiceLancamento extends DefaultApi<ILancamento> {
  cancel = async (id_lancamento: string) => {
    const { data } = await api.put<ILancamento[]>(
      `${endpoint}/${id_lancamento}/cancelar/`
    )
    return data
  }

  findAllByTipo = async (
    page: number,
    q?: string,
    ativo: TAtivo = true,
    tipo_lancamento = ''
  ): Promise<IResult<ILancamento>> => {
    const pageString = page ? `page=${page}` : ''
    const qString = q ? `q=${q}` : ''
    const ativoString = ativo !== undefined ? `ativo=${ativo}` : ''
    const tipolancamentoString =
      tipo_lancamento !== undefined ? `tipo_lancamento=${tipo_lancamento}` : ''
    const params = [pageString, qString, ativoString, tipolancamentoString]
      .filter((value) => value !== '')
      .join('&')

    const { data } = await api.get<IResult<ILancamento>>(
      `${this.endpoint}?${params}`
    )
    return data
  }

  pay = async ({
    id_lancamento,
    dt_baixa,
    juros_mora_pagos,
    tipo_pagamento,
    id_forma_pagamento,
    id_conta,
    id_evento_conta_corrente,
    valor
  }: IBaixaLancamento) => {
    const { data } = await api.put<ILancamento[]>(
      `${endpoint}/${id_lancamento}/baixar/`,
      {
        dt_baixa,
        juros_mora_pagos,
        tipo_pagamento,
        id_forma_pagamento,
        valor,
        id_conta,
        id_evento_conta_corrente
      }
    )
    return data
  }

  reversePayment = async (id_lancamento: string) => {
    const { data } = await api.put<ILancamento[]>(
      `${endpoint}/${id_lancamento}/estorno/`
    )
    return data
  }
}

const apiLancamento = new ServiceLancamento(endpoint, resourceId)

export const ApiLancamento = {
  ...apiLancamento,
  cancel: apiLancamento.cancel,
  pay: apiLancamento.pay,
  reversePayment: apiLancamento.reversePayment
}
