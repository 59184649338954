import { History, MoreHoriz, ManageSearch, Close } from '@mui/icons-material'
import { ReactNode, useState } from 'react'
import { IDashboard } from '../../../../services/dashboard/types'
import { DashboardWidgetSummary } from '../../../../types/DashboardWidget/DashboardWidgetSummary'
import { DashboardWidgetSummaryHeader } from '../../../../types/DashboardWidget/DashboardWidgetSummary/DashboardWidgetSummaryHeader'
import { DashboadWidgetSummaryHeaderToolbar } from '../../../../types/DashboardWidget/DashboardWidgetSummary/DashboardWidgetSummaryHeader/DashboadWidgetSummaryHeaderToolbar/index.tsx'
import { DashboardWidgetSummaryHeaderDatabar } from '../../../../types/DashboardWidget/DashboardWidgetSummary/DashboardWidgetSummaryHeader/DashboardWidgetSummaryHeaderDatabar'
import { DashboardWidgetSummaryBody } from '../../../../types/DashboardWidget/DashboardWidgetSummary/DashboardWidgetSummaryBody'
import { process } from './process'
import { DashboardWidget } from '../../../../types/DashboardWidget'
import { TWidgetState } from '../../../../types/DashboardWidget/WidgetState'
import { DashboardWidgetList } from '../../../../types/DashboardWidget/DashboardWidgetList'
import { DashboardWidgetListBody } from '../../../../types/DashboardWidget/DashboardWidgetList/DashboardWidgetListBody'
import { DashboardWidgetListHeader } from '../../../../types/DashboardWidget/DashboardWidgetList/DashboardWidgetListHeader'
import { ReactComponent as CloseButton } from '../../../../assets/images/botaoFechar.svg'
import { formata } from '../../../../utils/formataData'
import { getCodigo } from '../../../../utils/getCodigo'
import { corFundoLinha, defaultWidgetSummaryStyle } from '../lib/consts'
import { IRequisicaoEstado } from '../../../../services/requisicao/types'
import { cloneData } from '../../../../utils/cloneData'
import { DashboardWidgetForm } from '../../../../types/DashboardWidget/DashboardWidgetForm'
import { corTextoLinha } from '../../../../types/DashboardWidget/cores'
import { BoxStatus, StatusBox } from '../../../../components/StatusBox'
import { TWidgetAtividade } from '../lib/@types/TWidgetAtividade'

interface WidgetRequisicoesProps {
  data: IDashboard
  onStateChange: (tipoAtividade: TWidgetAtividade) => void
}

const getStatus = (requisicao: IRequisicaoEstado): ReactNode => {
  let status: BoxStatus = 'Ok'
  let text = ''
  if (requisicao.estado_requisicao === 'COMPLETO') {
    text = 'Completo'
  } else if (requisicao.atraso > 0) {
    status = 'Error'
    text = 'Em Atraso'
  } else {
    status = 'Info'
    text = 'Executando'
  }
  return <StatusBox caption={text} status={status} />
}

export function WidgetRequisicoes({
  data,
  onStateChange
}: WidgetRequisicoesProps) {
  const processedData = process(data)
  const widgetSummaryHeight = '400px'
  const widgetSummaryWidth = '400px'
  const widgetOtherWidth = '100%'
  const widgetOtherHeight = '840px'
  const [requisicaoAtual, setRequisicaoAtual] = useState<IRequisicaoEstado>()

  const [widgetState, setWidgetState] = useState<TWidgetState>('SUMMARY')
  const [maxWidgetHeight, setMaxWidgetHeight] =
    useState<string>(widgetSummaryHeight)
  const [maxWidgetWidth, setMaxWidgetWidth] =
    useState<string>(widgetSummaryWidth)

  const handleMoreClick = (): void => {
    setWidgetState('LIST')
    setMaxWidgetHeight(widgetOtherHeight)
    setMaxWidgetWidth(widgetOtherWidth)
    onStateChange('Requisicao')
  }

  const handleCloseListClick = (): void => {
    setWidgetState('SUMMARY')
    setMaxWidgetHeight(widgetSummaryHeight)
    setMaxWidgetWidth(widgetSummaryWidth)
    onStateChange(undefined)
  }

  const handleViewItemClick = (id: string): void => {
    setWidgetState('FORM')
    setMaxWidgetHeight(widgetOtherHeight)
    setMaxWidgetWidth(widgetOtherWidth)
    const newRequisicaoAtual = processedData.tableData.filter(
      (req) => req.id_requisicao === id
    )

    if (newRequisicaoAtual.length > 0) {
      setRequisicaoAtual(
        cloneData<IRequisicaoEstado>(newRequisicaoAtual.at(0)!)
      )
    }
    onStateChange('Requisicao')
  }

  const handleCloseFormClick = (): void => {
    setWidgetState('LIST')
    setMaxWidgetHeight(widgetOtherHeight)
    setMaxWidgetWidth(widgetOtherWidth)
    onStateChange('Requisicao')
  }

  const chartData = {
    labels: ['Atrasados', 'Regular'],

    datasets: [
      {
        label: 'Total de Itens',
        data: [
          processedData.summaryData.atrasados,
          processedData.summaryData.regular
        ],
        backgroundColor: ['rgb(233, 44, 44)', '#219653'],
        hoverOffset: 4
      }
    ]
  }

  const colunasLista = [
    {
      title: 'Código'
    },
    {
      title: 'Contrato'
    },
    {
      title: 'Serviço'
    },
    {
      title: 'Previsão de Início'
    },
    {
      title: 'Requisitante'
    },
    {
      title: 'SLA (horas)'
    },
    {
      title: 'Atraso Total (horas)'
    },
    {
      title: 'Estado Execução'
    },
    {
      title: '',
      width: 100
    }
  ]
  return (
    <>
      {widgetState === 'SUMMARY' && (
        <DashboardWidget.Root
          sx={{
            borderRadius: '20px',
            boxShadow:
              '0px 8px 16px -4px rgba(24, 39, 75, 0.08), 0px 6px 0px -6px rgba(0, 0, 0, 0.25)',
            padding: 0,
            backgroundColor: '#fff',
            margin: '2rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1rem',
            maxHeight: `${maxWidgetHeight}px`,
            maxWidth: `${maxWidgetWidth}px`
          }}
        >
          <DashboardWidgetSummary.Root sx={{ ...defaultWidgetSummaryStyle }}>
            <DashboardWidgetSummaryHeader.Root>
              <DashboadWidgetSummaryHeaderToolbar.Root>
                <DashboadWidgetSummaryHeaderToolbar.Type type="Requisições Não Concluídas" />
                <DashboadWidgetSummaryHeaderToolbar.Action
                  icon={MoreHoriz}
                  onClick={handleMoreClick}
                />
              </DashboadWidgetSummaryHeaderToolbar.Root>
              <DashboardWidgetSummaryHeader.Title
                title={processedData.summaryData.totalItens.toString()}
              />
              <DashboardWidgetSummaryHeaderDatabar.Root>
                {processedData.summaryData.headerData.map((dt, index) => (
                  <DashboardWidgetSummaryHeaderDatabar.Data
                    key={`wRequisicoesData${index}`}
                    data={Math.floor(dt.quantidade).toString()}
                    title={dt.descricao}
                  />
                ))}
              </DashboardWidgetSummaryHeaderDatabar.Root>
            </DashboardWidgetSummaryHeader.Root>
            <DashboardWidgetSummaryBody.Root>
              <DashboardWidgetSummaryBody.Pie data={chartData} />
            </DashboardWidgetSummaryBody.Root>
          </DashboardWidgetSummary.Root>
        </DashboardWidget.Root>
      )}
      {widgetState === 'LIST' && (
        <DashboardWidget.Root
          sx={{
            borderRadius: '20px',
            boxShadow:
              '0px 8px 16px -4px rgba(24, 39, 75, 0.08), 0px 6px 0px -6px rgba(0, 0, 0, 0.25)',
            padding: 0,
            backgroundColor: '#fff',
            margin: '2rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1rem',
            maxHeight: `${maxWidgetHeight}px`,
            maxWidth: `${maxWidgetWidth}px`
          }}
        >
          <DashboardWidgetList.Root
            sx={{
              width: '100%',
              height: `${maxWidgetHeight}px`,
              margin: 0
            }}
          >
            <DashboardWidgetListHeader.Root title={'Requisicoes'}>
              <DashboardWidgetListHeader.Data
                total={processedData.summaryData.totalItens}
                icon={History}
              />
              <DashboardWidgetListHeader.Actions>
                <DashboardWidgetListHeader.IconAction
                  icon={CloseButton}
                  onClick={handleCloseListClick}
                  tip={'Voltar para Dashboard'}
                  style={{
                    width: '40px',
                    height: '40px',
                    padding: 0,
                    margin: 0,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    borderRadius: '20px',
                    border: 0
                  }}
                />
              </DashboardWidgetListHeader.Actions>
            </DashboardWidgetListHeader.Root>
            <DashboardWidgetListBody.Root>
              <DashboardWidgetListBody.Table columnsDefs={colunasLista}>
                {processedData.tableData.map((requisicao, idx) => {
                  const values = [
                    getCodigo(requisicao.id_requisicao ?? ''),
                    requisicao.contrato.descricao,
                    requisicao.servico.nome ?? '',
                    formata(
                      new Date(requisicao.previsao_inicio),
                      "dd/MM/yyyy 'às' HH:mm"
                    ),
                    requisicao.requisitante.nome,
                    Math.floor(requisicao.sla).toString(),
                    Math.floor(requisicao.atraso).toString(),
                    getStatus(requisicao)
                  ]
                  return (
                    <DashboardWidgetListBody.Row
                      sx={{
                        backgroundColor: idx % 2 === 0 ? corFundoLinha : ''
                      }}
                      cellStyle={{
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        fontSize: '1rem',
                        color: corTextoLinha,
                        paddingBottom: 0,
                        paddingTop: 0,
                        borderBottom: 0,
                        height: '40px'
                      }}
                      values={values}
                      key={
                        requisicao.id_requisicao +
                        Math.floor(Math.random() * 10000).toString()
                      }
                    >
                      <DashboardWidgetListBody.Actions>
                        <DashboardWidgetListBody.Action
                          tip="Ver estado da requisição"
                          icon={ManageSearch}
                          color="primary"
                          sx={{ minWidth: '24px', padding: '6px' }}
                          onClick={() => {
                            handleViewItemClick(requisicao.id_requisicao ?? '')
                          }}
                        />
                      </DashboardWidgetListBody.Actions>
                    </DashboardWidgetListBody.Row>
                  )
                })}
              </DashboardWidgetListBody.Table>
            </DashboardWidgetListBody.Root>
          </DashboardWidgetList.Root>
        </DashboardWidget.Root>
      )}
      {widgetState === 'FORM' && (
        <DashboardWidget.Root
          sx={{
            borderRadius: '20px',
            boxShadow:
              '0px 8px 16px -4px rgba(24, 39, 75, 0.08), 0px 6px 0px -6px rgba(0, 0, 0, 0.25)',
            padding: 0,
            backgroundColor: '#fff',
            margin: '2rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1rem',
            maxHeight: `${maxWidgetHeight}px`,
            maxWidth: `${maxWidgetWidth}px`
          }}
        >
          <DashboardWidgetForm.Root
            sx={{
              // width: `${maxWidgetWidth}px`,
              width: `100%`,
              height: `${maxWidgetHeight}px`,
              margin: 0,
              padding: 0,
              textAlign: 'center',
              alignAlign: 'center',
              alignContent: 'center'
            }}
          >
            <DashboardWidgetForm.Header title={'Requisições'}>
              <DashboardWidgetForm.Actions>
                <DashboardWidgetForm.Action
                  tip="Voltar para tela anterior sem salvar modificações"
                  title="Cancelar"
                  icon={Close}
                  variant="outlined"
                  color="secondary"
                  sx={{ gap: 1, color: '#1C1C1C', fontWeight: 'bold' }}
                  onClick={() => handleCloseFormClick()}
                />
              </DashboardWidgetForm.Actions>
            </DashboardWidgetForm.Header>
            <DashboardWidgetForm.Requisicao data={requisicaoAtual!} />
          </DashboardWidgetForm.Root>
        </DashboardWidget.Root>
      )}
    </>
  )
}
