import { DefaultApi } from '../../../utils/defaultApi'
import { IResult, TAtivo } from '../../../utils/defaultApi/types'
import api from '../../api'
import { IParceiro } from './types'

const endpoint = '/financeiro/parceiros'
const resourceId = 'id_parceiro'

class ServiceParceiro extends DefaultApi<IParceiro> {
  findAllByTipo = async (
    page: number,
    q?: string,
    ativo: TAtivo = true,
    tipo_relacionamento?: string
  ): Promise<IResult<IParceiro>> => {
    const pageString = page ? `page=${page}` : ''
    const qString = q ? `q=${q}` : ''
    const ativoString = ativo !== undefined ? `ativo=${ativo}` : ''
    const tipo_relacionamentoString =
      tipo_relacionamento !== undefined
        ? `tipo_relacionamento=${tipo_relacionamento}`
        : ''
    const params = [pageString, qString, ativoString, tipo_relacionamentoString]
      .filter((value) => value !== '')
      .join('&')

    const { data } = await api.get<IResult<IParceiro>>(
      `${this.endpoint}?${params}`
    )
    return data
  }
  listAllCliente = async () => {
    const { data } = await api.get<IParceiro[]>(
      `${this.endpoint}/lista/cliente`
    )
    return data
  }

  listAllFornecedor = async () => {
    const { data } = await api.get<IParceiro[]>(
      `${this.endpoint}/lista/fornecedor`
    )
    return data
  }
}

const apiParceiro = new ServiceParceiro(endpoint, resourceId)

export const ApiParceiro = {
  ...apiParceiro,
  findByTipo: apiParceiro.findAllByTipo,
  listAllCliente: apiParceiro.listAllCliente,
  listAllFornecedor: apiParceiro.listAllFornecedor
}
