import { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  Modal,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/DeleteForever'
import CloseIcon from '@mui/icons-material/Close'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import useToast from '../../../hooks/toast/useToast'
import { Input } from '../../../components/Form/Input'
import { Select } from '../../../components/Form/Select'
import msg from '../../../utils/validationMessages'
import { useDocumento } from '../../../hooks/documento/useDocumento'
import { IDocumento } from '../../../services/documento/types'
import { Dialog } from '../../../components/Dialog'
import { useArvoreDocumental } from '../../../hooks/arvoreDcoumental/useArvoreDocumental'

type IFormDocData = {
  id: string
  id_caixa: string
  id_contrato: string
  id_tipo_documento: string
  descricao_tipo_documento: string
  descricao: string
  dados_pessoais: boolean
  dados_pessoais_sensiveis: boolean
  dados_criancas_adolescentes: boolean
  file?: any
}

const schema = yup
  .object({
    descricao: yup.string().required(msg.REQUIRED).min(4, msg.MIN(4)),
    id_tipo_documento: yup.string().required(msg.REQUIRED),
    arquivoDigital: yup.boolean(),
    file: yup
      .mixed()
      .test('file', 'Selecione um arquivo', (value) =>
        value.length > 0 ? true : false
      )
  })
  .required()

type FormDocumentoDigitalProps = {
  open: boolean
  handleClose: () => void
  idCaixa: string
  idContrato: string
  idTipoDocumento: string
}

export function FormDocumentoDigital({
  open,
  handleClose,
  idCaixa,
  idContrato,
  idTipoDocumento
}: FormDocumentoDigitalProps) {
  const { Toast } = useToast()
  const [docList, setDocList] = useState<IFormDocData[]>([])
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [openDialogConfirmCloseForm, setOpenDialogConfirmCloseForm] =
    useState<boolean>(false)
  const { data: tipoDocumento } = useArvoreDocumental.FindTiposDocumentos()
  const { mutateAsync: create } = useDocumento.Create()
  const { mutateAsync: createDocumentoDigital } =
    useDocumento.CreateDocumentoDigital()

  const {
    register,
    reset,
    control,
    handleSubmit,
    setFocus,
    formState: { errors, isSubmitSuccessful }
  } = useForm<IFormDocData>({
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    if (isSubmitSuccessful) {
      setFocus('descricao')
    }
  }, [isSubmitSuccessful, setFocus])

  const onSubmit = async (formDocData: IFormDocData) => {
    const {
      descricao,
      id_tipo_documento,
      dados_pessoais,
      dados_pessoais_sensiveis,
      dados_criancas_adolescentes,
      file
    } = formDocData

    const formArquivoDigital = {
      id_caixa: idCaixa,
      id_contrato: idContrato,
      id_tipo_documento,
      descricao,
      dados_pessoais,
      dados_pessoais_sensiveis,
      dados_criancas_adolescentes
    }

    const formData = new FormData()

    formData.append('file', file[0])
    formData.append('formData', JSON.stringify(formArquivoDigital))

    reset()
    await Toast(createDocumentoDigital(formData))
    handleClose()
  }

  const handleAddDocs = async () => {
    const formattedData = docList.map(
      ({
        id_tipo_documento,
        descricao,
        dados_pessoais,
        dados_pessoais_sensiveis,
        dados_criancas_adolescentes
      }) =>
        ({
          id_caixa: idCaixa,
          id_contrato: idContrato,
          id_tipo_documento,
          descricao,
          dados_pessoais,
          dados_pessoais_sensiveis,
          dados_criancas_adolescentes
        } as IDocumento)
    )
    await Toast(
      create(formattedData),
      'CREATED',
      'Documento(s) incluído(s) com sucesso!'
    )

    setOpenDialog(false)
    setDocList([])
    reset()
    handleClose()
  }

  const DeleteDoc = (id: string) => {
    const docFiltered = docList?.filter((doc) => doc.id !== id)
    setDocList(docFiltered)
  }

  const handleCloseForm = () => {
    setDocList([])
    setOpenDialogConfirmCloseForm(false)
    reset()
    handleClose()
  }

  return (
    <Modal
      open={open}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        top: '20%'
      }}
    >
      <Box
        borderRadius="4px"
        borderColor="lightgray"
        sx={{
          bgcolor: 'background.paper',
          boxShadow: 24,
          width: {
            sm: '780px',
            md: '880px'
          }
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          bgcolor="#2835ad"
          px={2}
          sx={{
            borderTopRightRadius: '4px',
            borderTopLeftRadius: '4px'
          }}
        >
          <Typography color="#FFF" py={1.5} variant="subtitle1">
            Inclusão de Documentos DIGITAL à Caixa
          </Typography>

          <Tooltip title="Fechar" arrow>
            <IconButton
              onClick={() =>
                docList.length > 0
                  ? setOpenDialogConfirmCloseForm(true)
                  : handleCloseForm()
              }
            >
              <CloseIcon sx={{ color: '#fff' }} />
            </IconButton>
          </Tooltip>
        </Box>

        <Box>
          <Stack p={2} component="form" onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={1} direction="row">
              <Input
                label="Descrição*"
                {...register('descricao')}
                message={errors.descricao?.message}
                sx={{ width: '1800px' }}
              />

              <Select
                label="Tipo de documento*"
                placeholder="Selecione o Tipo de documento"
                data={tipoDocumento}
                fields={['id_tipo_documento', 'descricao']}
                defaultValue={idTipoDocumento}
                {...register('id_tipo_documento')}
                message={errors.id_tipo_documento?.message}
              />

              <Box>
                <Stack gap={0} direction="row">
                  <Controller
                    control={control}
                    name="dados_pessoais"
                    defaultValue={false}
                    render={({ field: { value, ...field } }) => (
                      <Tooltip
                        title="Contém dados pessoais (nome, cpf, endereço)"
                        arrow
                      >
                        <FormControlLabel
                          control={<Checkbox {...field} checked={!!value} />}
                          label="P"
                        />
                      </Tooltip>
                    )}
                  />

                  <Controller
                    control={control}
                    name="dados_pessoais_sensiveis"
                    defaultValue={false}
                    render={({ field: { value, ...field } }) => (
                      <Tooltip
                        title="Contém dados pessoais sensíveis (ficha médica, biometria)"
                        arrow
                      >
                        <FormControlLabel
                          control={<Checkbox {...field} checked={!!value} />}
                          label="S"
                        />
                      </Tooltip>
                    )}
                  />

                  <Controller
                    control={control}
                    name="dados_criancas_adolescentes"
                    defaultValue={false}
                    render={({ field: { value, ...field } }) => (
                      <Tooltip
                        title="Contém dados de crianças e/ou adolescentes"
                        arrow
                      >
                        <FormControlLabel
                          control={<Checkbox {...field} checked={!!value} />}
                          label="C"
                        />
                      </Tooltip>
                    )}
                  />
                </Stack>
              </Box>
            </Stack>

            {!!docList.length && (
              <>
                <Stack
                  gap={2}
                  direction="row"
                  alignItems="center"
                  sx={{ marginTop: 2, padding: 0 }}
                >
                  <Typography sx={{ width: '460px' }} fontWeight="600">
                    Descrição
                  </Typography>
                  <Typography sx={{ width: '190px' }} fontWeight="600">
                    Tipo de Documento
                  </Typography>
                  <Typography fontWeight="600">Privacidade</Typography>
                  <Typography />
                </Stack>

                <Divider sx={{ margin: 0, padding: 0 }} />
              </>
            )}

            <Box
              sx={{
                overflow: 'auto',
                maxHeight: 320
              }}
            >
              {docList.map((doc) => (
                <Stack
                  key={doc.id}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography sx={{ width: '460px' }}>
                    {doc.descricao}
                  </Typography>
                  <Typography sx={{ width: '190px' }}>
                    {doc.descricao_tipo_documento}
                  </Typography>
                  <Typography sx={{ width: '90px' }}>
                    {doc.dados_pessoais ? (
                      <span>
                        [<strong>{' P '}</strong>]
                      </span>
                    ) : null}
                    {doc.dados_pessoais_sensiveis ? (
                      <span>
                        [<strong>{' S '}</strong>]
                      </span>
                    ) : null}
                    {doc.dados_criancas_adolescentes ? (
                      <span>
                        [<strong>{' C '}</strong>]
                      </span>
                    ) : null}
                  </Typography>
                  <Tooltip title="Excluir documento" arrow>
                    <IconButton onClick={() => DeleteDoc(doc.id)}>
                      <DeleteIcon color="primary" fontSize="large" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              ))}
            </Box>

            <Input
              label=""
              type="file"
              sx={{ pt: 1 }}
              {...register('file')}
              message={errors.file?.message}
            />

            <Divider />

            <Stack direction="row" gap={2} p={2} justifyContent="flex-end">
              <Button variant="contained" type="submit">
                Upload de arquivo
              </Button>
            </Stack>
          </Stack>
        </Box>

        <Dialog
          handleAnswerNo={() => setOpenDialog(false)}
          handleAnswerYes={handleAddDocs}
          messageText="Tem certeza que deseja continuar?"
          title="Atenção"
          openDialog={openDialog}
        />

        <Dialog
          handleAnswerNo={() => setOpenDialogConfirmCloseForm(false)}
          handleAnswerYes={handleCloseForm}
          messageText="Existe(m) documento(s) pendente(s) de confirmação. Tem certeza que deseja fechar?"
          title="Atenção"
          openDialog={openDialogConfirmCloseForm}
        />
      </Box>
    </Modal>
  )
}
