import { ComponentGroup } from '../../../../components/ComponentGroup'
import { Input } from '../../../../components/Form/Input'
import { IRequisicaoEstado } from '../../../../services/requisicao/types'
import { getCodigo } from '../../../../utils/getCodigo'
import { Permissions } from '../../../../types/permissions'
import { formata } from '../../../../utils/formataData'
import { Timeline } from './Timeline'
import {
  Checkbox,
  FormControlLabel,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import { useRequisicao } from '../../../../hooks/requisicao/useRequisicao'
import { Loading } from '../../../../components/Loading'
import { useEffect, useState } from 'react'
import { IMovimentacao } from '../../../../services/movimentacao/types'
import { useUsuario } from '../../../../hooks/usuario/useUsuario'

interface DetalhesRequisicaoProps {
  statusRequisicao: IRequisicaoEstado
}

export function DetalhesRequisicao({
  statusRequisicao
}: DetalhesRequisicaoProps) {
  const { data: usuario } = useUsuario.FindByUsername()
  const hasAccess = (permission: number) =>
    usuario?.permissoes.includes(permission) ?? false
  const { servico, item_atrasado } = statusRequisicao
  const { data: requisicao, isLoading } = useRequisicao.FindOne(
    statusRequisicao.id_requisicao
  )

  const [observacoes, setObservacoes] = useState<IMovimentacao[]>([])

  useEffect(() => {
    if (requisicao) {
      const movs = requisicao.itens_requisicao
        ?.map((item) =>
          item.movimentacoes.filter(
            (mov) => !!mov.observacoes && mov.observacoes?.trim() !== ''
          )
        )
        .flat()
      setObservacoes(movs ?? [])
    }
  }, [requisicao])

  const getDescricaoItem = (idItemRequisicao: string): string => {
    const item = requisicao?.itens_requisicao?.find(
      (item) => item.id_item_requisicao === idItemRequisicao
    )
    if (item) {
      const retVal = item.id_caixa
        ? item.caixa?.identificacao
        : item.documento?.descricao
      const compl = !hasAccess(Permissions.EXIBIR_ID_CAIXA)
        ? ''
        : item.id_caixa
        ? ` (${getCodigo(item.id_caixa)})`
        : ` (${getCodigo(item.id_documento)})`
      return retVal + compl ?? ''
    }
    return ''
  }

  const { atividades: listaAtividades } = statusRequisicao.servico
  const atividades = listaAtividades
    .sort((a, b) => (a.ordem < b.ordem ? -1 : b.ordem < a.ordem ? 1 : 0))
    .map((atividade) => atividade.tipo_atividade.descricao)

  let atividadeAtual: number = listaAtividades.length

  if (statusRequisicao.item_atrasado) {
    atividadeAtual = listaAtividades.findIndex(
      (atividade) =>
        atividade.id_atividade === statusRequisicao.item_atrasado.id_atividade
    )
  }

  if (isLoading) return <Loading />

  return (
    <Stack gap={4} sx={{ marginRight: '1rem' }}>
      <Stack gap={2}>
        <Timeline steps={atividades} step={atividadeAtual} />
        <Stack gap={2} direction={'row'}>
          <Input
            label="Código"
            defaultValue={getCodigo(statusRequisicao.id_requisicao)}
            disabled={true}
          />
          <Input label="Serviço" defaultValue={servico.nome} disabled={true} />
          <Input
            label="Estado da Requisição"
            defaultValue={statusRequisicao.estado_requisicao}
            disabled={true}
          />
        </Stack>
      </Stack>
      <Stack gap={2}>
        <Stack gap={2} direction="row">
          <Input
            label="Previsão de início"
            defaultValue={formata(
              statusRequisicao.previsao_inicio,
              'dd/MM/yyyy HH:mm'
            )}
            disabled={true}
          />
          <Input
            label="Sugestão de início"
            defaultValue={formata(
              statusRequisicao.sugestao_inicio,
              'dd/MM/yyyy HH:mm'
            )}
            disabled={true}
          />
          <Input
            label="Requisitante"
            defaultValue={statusRequisicao.requisitante.nome}
            disabled={true}
          />
        </Stack>
        <Input
          label="Observações"
          defaultValue={statusRequisicao.observacoes}
          disabled={true}
        />
        <Stack direction={'row'} gap={2}>
          <Input
            label="Itens Requisitados"
            defaultValue={statusRequisicao.quantidade_itens}
            disabled={true}
          />
          <Input
            label="SLA (horas)"
            defaultValue={Math.floor(statusRequisicao.sla)}
            disabled={true}
          />
          <Input
            label="Atraso total (horas)"
            defaultValue={Math.floor(statusRequisicao.atraso)}
            disabled={true}
          />
        </Stack>
        <Stack direction={'row'} gap={2}>
          <ComponentGroup
            title="Classificação"
            aria-labelledby="label-classificacao"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              gap: 2
            }}
          >
            <FormControlLabel
              control={<Checkbox checked={statusRequisicao.urgente} />}
              label="Urgente"
              disabled={true}
            />
          </ComponentGroup>
        </Stack>
      </Stack>

      <Stack gap={2}>
        {(requisicao?.itens_requisicao?.length ?? 0) > 0 && (
          <ComponentGroup title="Itens da Requisição">
            <TableContainer sx={{ maxHeight: 180 }}>
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '14px',
                        backgroundColor: '#f2f2f2'
                      }}
                    >
                      Tipo
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '14px',
                        backgroundColor: '#f2f2f2'
                      }}
                    >
                      Descrição
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '14px',
                        backgroundColor: '#f2f2f2'
                      }}
                    >
                      Setor
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '14px',
                        backgroundColor: '#f2f2f2'
                      }}
                    >
                      Endereço
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '14px',
                        backgroundColor: '#f2f2f2'
                      }}
                    >
                      Estado
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {requisicao?.itens_requisicao?.map((itemRequisicao) => {
                    return (
                      <TableRow
                        key={itemRequisicao.id_item_requisicao + 'item'}
                      >
                        <TableCell>
                          {itemRequisicao.id_caixa ? 'Caixa' : 'Documento'}
                        </TableCell>
                        <TableCell>
                          {itemRequisicao.id_caixa
                            ? itemRequisicao.caixa?.identificacao
                            : itemRequisicao.documento?.descricao}
                        </TableCell>
                        <TableCell>
                          {itemRequisicao.id_caixa
                            ? itemRequisicao.caixa?.setor?.nome
                            : itemRequisicao.documento?.setor?.nome}
                        </TableCell>
                        <TableCell>
                          {itemRequisicao.id_caixa
                            ? itemRequisicao?.caixa?.endereco
                            : itemRequisicao?.documento?.endereco}
                        </TableCell>
                        <TableCell>
                          {itemRequisicao.id_caixa
                            ? itemRequisicao.caixa?.estado
                            : itemRequisicao.documento?.estado}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </ComponentGroup>
        )}

        {observacoes.length > 0 && (
          <ComponentGroup title="Observações">
            <TableContainer sx={{ maxHeight: 200 }}>
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '14px',
                        backgroundColor: '#f2f2f2'
                      }}
                    >
                      Item
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '14px',
                        backgroundColor: '#f2f2f2'
                      }}
                    >
                      Atividade
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '14px',
                        backgroundColor: '#f2f2f2'
                      }}
                    >
                      Executante
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '14px',
                        backgroundColor: '#f2f2f2'
                      }}
                    >
                      Horário Exec.
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '14px',
                        backgroundColor: '#f2f2f2'
                      }}
                    >
                      Estado
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '14px',
                        backgroundColor: '#f2f2f2'
                      }}
                    >
                      Observação
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {observacoes.map((mov) => (
                    <TableRow key={`${mov.id_movimentacao}`}>
                      <TableCell>
                        {getDescricaoItem(mov.id_item_requisicao)}
                      </TableCell>
                      <TableCell>
                        {
                          mov.preco_atividade?.atividade.tipo_atividade
                            .identificacao
                        }
                      </TableCell>
                      <TableCell>{mov.responsavel?.pessoa.nome}</TableCell>
                      <TableCell>
                        {formata(mov.data_inicio, 'dd/MM/yyyy HH:mm')}
                      </TableCell>
                      <TableCell>{mov.estado}</TableCell>
                      <TableCell>{mov.observacoes}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </ComponentGroup>
        )}

        {item_atrasado ? (
          <ComponentGroup title="Item menos atendido">
            <Stack gap={2} direction={'row'}>
              <Input
                label="SLA (horas)"
                defaultValue={Math.floor(item_atrasado.sla)}
                disabled={true}
              />
              <Input
                label="Atraso (horas)"
                defaultValue={Math.floor(item_atrasado.atraso)}
                disabled={true}
              />
            </Stack>
            {item_atrasado.caixa ? (
              <ComponentGroup title="Caixa">
                <Stack gap={2}>
                  <Stack gap={2} direction="row">
                    <Input
                      label="Identificação"
                      defaultValue={item_atrasado.caixa.identificacao}
                      disabled={true}
                    />
                    <Input
                      label="Estado"
                      defaultValue={item_atrasado.caixa.estado}
                      disabled={true}
                    />
                    <Input
                      label="Tipo de Caixa"
                      defaultValue={item_atrasado.caixa.tipoCaixa.descricao}
                      disabled={true}
                    />
                  </Stack>
                  <Stack gap={2} direction="row">
                    <Input
                      label="Conteúdo"
                      defaultValue={item_atrasado.caixa.conteudo}
                      disabled={true}
                    />
                    <Input
                      label="Sequência início"
                      defaultValue={item_atrasado.caixa.sequencia_inicio}
                      disabled={true}
                    />
                    <Input
                      label="Sequência fim"
                      defaultValue={item_atrasado.caixa.sequencia_fim}
                      disabled={true}
                    />
                  </Stack>
                </Stack>
              </ComponentGroup>
            ) : (
              <ComponentGroup title="Documento">
                <Stack gap={2}>
                  <Input
                    label="Descrição"
                    defaultValue={item_atrasado.documento.descricao}
                    disabled={true}
                  />
                </Stack>
              </ComponentGroup>
            )}
          </ComponentGroup>
        ) : (
          ''
        )}
      </Stack>
    </Stack>
  )
}
